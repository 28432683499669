import { createStore } from 'vuex'
import { loginUser, logoutUser } from '@/services/api/auth'
import { VuexPersistence } from 'vuex-persist'
import Cookies from 'js-cookie'
import config from '@/services/api/config.js'

/**
 * Represents the state of the application.
 * @typedef {Object} State
 * @property {string} user - The user's username.
 * @property {boolean} isLoggedIn - Whether the user is logged in.
 * @property {Array} permissions - The user's permissions.
 */
const State = {
  user: null,
  firstName: '',
  lastName: '',
  isLoggedIn: false,
  permissions: []
}

/**
 * The VuexPersistence plugin for persisting the state of the application. This plugin uses cookies to store the state.
 * @type {VuexPersistence}
 */
const vuexCookie = new VuexPersistence({
  restoreState: (key, storage) => {
    const data = Cookies.get(key)
    if (!data) return undefined
    return JSON.parse(data)
  },
  saveState: (key, state, storage) => {
    Cookies.set(key, JSON.stringify(state), { expires: config.refreshTokenDays, secure: config.secureCookie })
  },
  reducer: (state) => ({
    user: state.user,
    firstName: state.firstName,
    lastName: state.lastName,
    isLoggedIn: state.isLoggedIn,
    permissions: state.permissions
  })

})

/**
 * The Vuex store for the application.
 * @type {Store<State>}
 * @see {@link https://vuex.vuejs.org/}
 */
export default createStore({
  state: State,
  getters: {
  },
  mutations: {
    /**
     * Sets the user's username and sets the isLoggedIn flag to true.
     * @param {State} state - The state of the application.
     * @param {Object} userId - The user's username.
     */
    loginSuccess (state, payload) {
      state.user = payload.user.userId
      if (payload.user.firstName) state.firstName = payload.user.firstName ?? ''
      if (payload.user.lastName) state.lastName = payload.user.lastName ?? ''
      state.isLoggedIn = true
    },
    /**
     * Sets the user's username and sets the isLoggedIn flag to false.
     * @param {State} state
     */
    logout (state) {
      state.user = null
      state.firstName = ''
      state.lastName = ''
      state.isLoggedIn = false
      state.permissions = []
    }
  },
  actions: {
    /**
     * Logs the user in.
     * @param {Commit} param0 - The commit function.
     * @param {Object} param1 - The username and password.
     * @returns {Promise}
     */
    login ({ commit }, { username, password }) {
      return loginUser(username, password)
        .then((res) => {
          commit({ type: 'loginSuccess', user: { userId: username, firstName: res.first_name, lastName: res.last_name } })
          return Promise.resolve()
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    /**
     * Logs the user out.
     * @param {Comm} param0 - The commit function.
     */
    logout ({ commit }) {
      logoutUser()
      commit('logout')
    }
  },
  plugins: [vuexCookie.plugin]
})
