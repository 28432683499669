import { authRequest, ACCESS_TOKEN } from './auth'

/**
 * Get all ads
 * @returns {Promise} A promise that resolves with the response data.
 */
const getAds = () => {
  authRequest.defaults.headers.Authorization = `Bearer ${window.localStorage.getItem(ACCESS_TOKEN)}`
  authRequest.defaults.headers['Content-Type'] = 'application/json'
  return authRequest.get('/api/asconf/ads/')
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error))
}
/**
 * Post a new ad
 * @param {Object} data - The data to post.
 * @returns {Promise} A promise that resolves with the response data.
 */
const postAds = (data) => {
  authRequest.defaults.headers.Authorization = `Bearer ${window.localStorage.getItem(ACCESS_TOKEN)}`
  authRequest.defaults.headers['Content-Type'] = 'application/json'
  return authRequest.post('/api/asconf/ads/', data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error))
}
/**
 * Put an ad
 * @param {Number} id - The id of the ad to put.
 * @param {Object} data - The data to put.
 * @returns {Promise} A promise that resolves with the response data.
 */
const putAds = (id, data) => {
  authRequest.defaults.headers.Authorization = `Bearer ${window.localStorage.getItem(ACCESS_TOKEN)}`
  authRequest.defaults.headers['Content-Type'] = 'application/json'
  return authRequest.put(`/api/asconf/ads/${id}/`, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error))
}
/**
 * Delete an ad
 * @param {Number} id - The id of the ad to delete.
 * @returns {Promise} A promise that resolves with the response data.
 */
const deleteAds = (id) => {
  authRequest.defaults.headers.Authorization = `Bearer ${window.localStorage.getItem(ACCESS_TOKEN)}`
  authRequest.defaults.headers['Content-Type'] = 'application/json'
  return authRequest.delete(`/api/asconf/ads/${id}/`)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error))
}

export { getAds, postAds, putAds, deleteAds }
