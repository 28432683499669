import { createApp } from 'vue/dist/vue.esm-bundler.js'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
// import { loadFonts } from './plugins/webfontloader'

import Unicon from 'vue-unicons'
import { uniSignInAlt, uniSignOutAlt, uniAnalytics, uniSchedule, uniIncomingCall, uniPhoneVolume, uniSave, uniPlus, uniSearch } from 'vue-unicons/dist/icons'

// CSS imports
import './assets/css/global.css'

Unicon.add([uniSignInAlt, uniSignOutAlt, uniAnalytics, uniSchedule, uniIncomingCall, uniPhoneVolume, uniSave, uniPlus, uniSearch])

// loadFonts()

createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .use(Unicon)
  .mount('#app')
