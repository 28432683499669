<template>
  <v-chip closable variant="outlined" @click:close.stop="$emit('close', { id: id })" @click="$emit('click-chip', { id: id })"><span >{{ name }}</span></v-chip>
</template>

<script>
export default {
  name: 'ConsChip',
  props: {
    id: Number,
    name: String
  },
  emits: ['close', 'click-chip']
}
</script>
