import { authRequest, ACCESS_TOKEN } from './auth'

/**
 * Get the live queue
 * @returns {Promise} A promise that resolves with the response data.
 */
const getLiveQueue = async () => {
  authRequest.defaults.headers.Authorization = `Bearer ${window.localStorage.getItem(ACCESS_TOKEN)}`
  authRequest.defaults.headers['Content-Type'] = 'application/json'
  return authRequest.get('/api/asconf/external/?type=GetCallQueue', { timeout: 300000 })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error))
}

export { getLiveQueue }
