<template>
  <v-container>
    <v-row>
      <v-col clos="12">
        <h1>Oglasi</h1>
      </v-col>
    </v-row>
    <v-row v-if="loading">
      <v-col cols="12">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </v-col>
    </v-row>
    <v-row v-if="server_data">
      <v-col>
        <v-card variant="tonal" width="100%" class="text-left h-100">
          <template v-slot:text>
            <v-table>
              <thead>
                <tr>
                  <th class="text-left">Aktivni oglasi</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="ad in server_data.active_ads"
                  :key="ad.id"
                >
                  <td>
                    <v-row>
                      <v-col>
                        {{ ad.name }}
                      </v-col>
                      <v-col class="d-flex justify-end">
                        <v-btn
                          color="primary"
                          icon="mdi-minus"
                          size="x-small"
                        >
                        </v-btn> &nbsp;
                        <v-btn
                          color="primary"
                          icon="mdi-headphones"
                          size="x-small"
                        ></v-btn>
                      </v-col>
                    </v-row>
                  </td>
                </tr>
              </tbody>
            </v-table>
          </template>
        </v-card>
      </v-col>
      <v-col>
        <v-card variant="tonal" width="100%" class="text-left h-100">
          <template v-slot:text>
            <v-table>
              <thead>
                <tr>
                  <th class="text-left">Neaktivni oglasi</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="ad in server_data.inactive_ads"
                  :key="ad.id"
                >
                  <td>
                    <v-row>
                      <v-col>
                        {{ ad.name }}
                      </v-col>
                      <v-col class="d-flex justify-end">
                        <v-btn
                          color="primary"
                          icon="mdi-plus"
                          size="x-small"
                        >
                        </v-btn> &nbsp;
                        <v-btn
                          color="primary"
                          icon="mdi-delete"
                          size="x-small"
                        ></v-btn> &nbsp;
                        <v-btn
                          color="primary"
                          icon="mdi-headphones"
                          size="x-small"
                        ></v-btn>
                      </v-col>
                    </v-row>
                  </td>
                </tr>
              </tbody>
            </v-table>
          </template>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="server_data">
      <v-col>
        <v-card variant="tonal" width="100%" class="text-left">
        <template v-slot:text>
          <v-row>
            <v-col class="d-flex align-center">
              <v-text-field
                label="Perioda osvežavanja (h)"
                hide-details
              >
                <template v-slot:append>
                  <v-btn
                    color="primary"
                  >
                    Spremeni
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
            <v-col class="pl-11 d-flex align-center">
              <v-btn
                color="primary"
              >
                Dodaj oglas
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getAds } from '@/services/api/ads'

export default {
  name: 'AdView',
  data () {
    return {
      server_data: null,
      error: null,
      loading: false
    }
  },
  created () {
    this.$watch(
      () => this.$route.params,
      () => {
        this.load_data()
      },
      { immediate: true }
    )
  },
  methods: {
    load_data () {
      this.loading = true
      this.server_data = null
      getAds()
        .then(response => {
          this.server_data = response
          this.loading = false
        })
        .catch(error => {
          this.error = error
        })
    }
  }
}
</script>
