<template>
<div class="outer">
    <div class="background"></div>
    <div class="spinner">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
    </div>
    <span>
        {{text}}
    </span>
</div>
</template>

<script>
export default {
  name: 'ZrfrSpinner',
  props: ['text'],
  data () {
    return {
    }
  },
  methods: {}
}
</script>

<style scoped>
.background {
    position: absolute;
    width:100%;
    height: 100%;
    left: 0;
    top: 0;
    background: var(--bg2);
    /* backdrop-filter: blur(3px); */
    z-index: 0;
}
.outer {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    width: 100%;
    height: 100%;
    min-height: max-content;
    margin: 0;
    padding: 0.75rem 0;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 99;
    overflow: hidden;
}
.spinner {
  width: max-content;
  text-align: center;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  gap: 3px;
  z-index: 1;
}
.outer > span {
    color: var(--color-disabled);
    font-size: 0.8rem;
    z-index: 1;
}

.spinner > div {
  width: 11px;
  height: 11px;
  background-color: var(--accent);

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1s infinite ease-in-out both;
  animation: sk-bouncedelay 1s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}
</style>
