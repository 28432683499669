import { authRequest, ACCESS_TOKEN } from './auth'

const ping = () => {
  const extraParameters = { params: { id: 'PONG' } }
  authRequest.defaults.headers.Authorization = `Bearer ${window.localStorage.getItem(ACCESS_TOKEN)}`
  authRequest.defaults.headers['Content-Type'] = 'application/json'
  return authRequest.get('/api/ping/', extraParameters)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error))
}

export { ping }
