<template>
  <v-form
    v-on:submit="handleAddConsultant"
    v-model="valid"
    validate-on="blur"
  >
    <v-row>
      <v-col cols="7">
        <v-text-field
          v-if="consultant"
          :model-value="consultant.name"
          id="name"
          name="name"
          label="Ime in priimek"
          :rules="nameRules"
        ></v-text-field>
        <v-text-field
          v-else
          id="name"
          name="name"
          label="Ime in priimek"
          :rules="nameRules"
        ></v-text-field>
        <v-text-field
          v-if="consultant"
          :model-value="consultant.path"
          id="path"
          name="path"
          label="Predpona zvočnih datotek"
          :rules="pathRules"
        ></v-text-field>
        <v-text-field
          v-else
          id="path"
          name="path"
          label="Predpona zvočnih datotek"
          :rules="pathRules"
        ></v-text-field>
        <v-text-field
          v-if="consultant"
          :model-value="consultant.ext"
          id="ext"
          name="ext"
          label="Queue"
          :rules="extRules"
        ></v-text-field>
        <v-text-field
          v-else
          id="ext"
          name="ext"
          label="Queue"
          :rules="extRules"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-file-input
          show-size
          counter
          id="nesvetuje"
          name="nesvetuje"
          label="Zvočna datoteka 'ne svetuje'"
        ></v-file-input>
        <v-file-input
          show-size
          counter
          id="nadomesca"
          name="nadomesca"
          label="Zvočna datoteka 'nadomešča'"
        ></v-file-input>
        <v-file-input
          show-size
          counter
          id="svetovanje"
          name="svetovanje"
          label="Zvočna datoteka 'svetovanje'"
        ></v-file-input>
        <v-file-input
          show-size
          counter
          id="trenutno_nesvetuje"
          name="trenutno_nesvetuje"
          label="Zvočna datoteka 'trenutno ne svetuje'"
        ></v-file-input>
      </v-col>
    </v-row>
    <v-btn
      color="primary"
      type="submit"
    >
      Shrani
    </v-btn>
  </v-form>
</template>

<script>
import { postConsultants, putConsultants } from '@/services/api/consultants'

export default {
  name: 'AddConsultantForm',
  emits: ['add-consultant', 'edit-consultant'],
  props: {
    type: {
      type: String,
      required: false,
      default: 'add'
    },
    consultant: {
      type: Object,
      required: false,
      default: null
    }
  },
  data: () => ({
    valid: true,
    nameRules: [
      v => !!v || 'Ime in priimek je obvezen podatek.'
    ],
    pathRules: [
      v => !!v || 'Predpona zvočnih datotek je obvezen podatek.'
    ],
    extRules: [
      v => !!v || 'Queue je obvezen podatek.'
    ]
  }),
  methods: {
    handleAddConsultant (event) {
      event.preventDefault()
      const formData = new FormData(event.target)
      const data = Object.fromEntries(formData)
      if (this.type === 'add' && this.valid) {
        postConsultants(data)
          .then((response) => {
            this.$emit('add-consultant', { err: null, response: response.data })
          })
          .catch((error) => {
            this.$emit('add-consultant', { err: error, response: null })
          })
      } else if (this.type === 'edit') {
        putConsultants(this.consultant.id, data)
          .then((response) => {
            this.$emit('edit-consultant', { err: null, response: response.data })
          })
          .catch((error) => {
            this.$emit('edit-consultant', { err: error, response: null })
          })
      }
    }
  }
}
</script>
