import { authRequest, ACCESS_TOKEN } from './auth'

let controller = new AbortController()
// let controllerUserDetails = new AbortController()
let controllerNumberPhonecalls = new AbortController()

const getCallUserDetails = async (dataType, id) => {
  /**
   * Get the details of a user in the queue
   * @param {String} dataType - The type of data to get.
   * @param {Number} id - The id of the contact to get.
   * @returns {Promise} A promise that resolves with the response data.
   */
  authRequest.defaults.headers.Authorization = `Bearer ${window.localStorage.getItem(ACCESS_TOKEN)}`
  authRequest.defaults.headers['Content-Type'] = 'application/json'
  // controllerUserDetails.abort()
  // controllerUserDetails = new AbortController()
  return authRequest.get(`/api/asconf/external/?type=GetCallUserDetails&data_type=${dataType}&id=${id}`, { timeout: 120000 })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error))
}

const getNumberOfPhonecalls = async () => {
  /** Get the number of phonecalls in for the day
   * @returns {Promise} A promise that resolves with the response data.
   */
  authRequest.defaults.headers.Authorization = `Bearer ${window.localStorage.getItem(ACCESS_TOKEN)}`
  authRequest.defaults.headers['Content-Type'] = 'application/json'
  controllerNumberPhonecalls.abort()
  controllerNumberPhonecalls = new AbortController()
  return authRequest.get('/api/asconf/external/?type=GetNumberOfPhonecalls', { timeout: 120000, signal: controllerNumberPhonecalls.signal })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error))
}

const getAccountDetails = async (accountID) => {
  /** Get the details of an account
   * @param {Number} accountID - The id of the account to get.
   * @returns {Promise} A promise that resolves with the response data.
   */
  authRequest.defaults.headers.Authorization = `Bearer ${window.localStorage.getItem(ACCESS_TOKEN)}`
  authRequest.defaults.headers['Content-Type'] = 'application/json'
  return authRequest.get(`/api/asconf/external/?type=GetAccountDetails&account_id=${accountID}`, { timeout: 120000 })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error))
}

const createCall = (callData) => {
  /** Create a call
   * @param {Object} callData - The call data to create.
   * @returns {Promise} A promise that resolves with the response data.
   */
  authRequest.defaults.headers.Authorization = `Bearer ${window.localStorage.getItem(ACCESS_TOKEN)}`
  authRequest.defaults.headers['Content-Type'] = 'application/json'
  return authRequest.post('/api/asconf/external/', callData)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error))
}

const getRedicrectCall = async (sipId) => {
  /** Get the redirect call
   * @returns {Promise} A promise that resolves with the response data.
   */
  authRequest.defaults.headers.Authorization = `Bearer ${window.localStorage.getItem(ACCESS_TOKEN)}`
  authRequest.defaults.headers['Content-Type'] = 'application/json'
  return authRequest.get(`/api/asconf/external/?type=RedirectCall&sip_id=${sipId}`, { timeout: 120000 })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error))
}

const getUserPreviousCalls = async (id, page = 1, perPage = 10, type = null) => {
  /** Get the previous calls for a user
   * @param {Number} contactId - The id of the contact to get.
   * @returns {Promise} A promise that resolves with the response data.
   */
  const perPageNum = perPage ? `&per_page=${perPage}` : ''
  const pageNum = page ? `&page=${page}` : ''
  const typeData = type ? `&data_type=${type}` : ''
  authRequest.defaults.headers.Authorization = `Bearer ${window.localStorage.getItem(ACCESS_TOKEN)}`
  authRequest.defaults.headers['Content-Type'] = 'application/json'
  return authRequest.get(`/api/asconf/external/?type=GetUserPreviousCalls&id=${id}${perPageNum}${pageNum}${typeData}`, { timeout: 120000 })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error))
}

const getAllCalls = async (page = 1, perPage = 10, search = null, type = null) => {
  /** Get the todays calls
   * @returns {Promise} A promise that resolves with the response data.
  */
  const searcData = search ? `&search=${search}` : ''
  const perPageNum = perPage ? `&per_page=${perPage}` : ''
  const pageNum = page ? `&page=${page}` : ''
  const typeData = type ? `&data_type=${type}` : ''
  const url = `/api/asconf/external/?type=GetAllCalls${searcData}${perPageNum}${pageNum}${typeData}`
  authRequest.defaults.headers.Authorization = `Bearer ${window.localStorage.getItem(ACCESS_TOKEN)}`
  authRequest.defaults.headers['Content-Type'] = 'application/json'
  controller.abort()
  controller = new AbortController()
  return authRequest.get(url, { timeout: 120000, signal: controller.signal })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error))
}

const toggleInterestingCall = async (callId, newVal) => {
  /** Toggle the interesting call
   * @returns {Promise} A promise that resolves with the response data.
   */
  const url = `/api/asconf/external/?type=InterestingCall&callhistory_id=${callId}&new_val=${newVal}`
  authRequest.defaults.headers.Authorization = `Bearer ${window.localStorage.getItem(ACCESS_TOKEN)}`
  authRequest.defaults.headers['Content-Type'] = 'application/json'
  return authRequest.get(url, { timeout: 120000 })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error))
}

const searchQuery = async (query) => {
  authRequest.defaults.headers.Authorization = `Bearer ${window.localStorage.getItem(ACCESS_TOKEN)}`
  authRequest.defaults.headers['Content-Type'] = 'application/json'
  if (query.expand) {
    return authRequest.get(`/api/asconf/external/?type=SearchQuery&entity=${query.entity}&select=${query.select}&filter=${query.filter}&expand=${query.expand}`, { timeout: 120000 })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error))
  } else {
    return authRequest.get(`/api/asconf/external/?type=SearchQuery&entity=${query.entity}&select=${query.select}&filter=${query.filter}`, { timeout: 120000 })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error))
  }
}

const getChartData = async (query) => {
  authRequest.defaults.headers.Authorization = `Bearer ${window.localStorage.getItem(ACCESS_TOKEN)}`
  authRequest.defaults.headers['Content-Type'] = 'application/json'
  return authRequest.get(`/api/asconf/external/?type=GetChartData&entity=${query.entity}&select=${query.select}&filter=${query.filter}`, { timeout: 120000 })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error))
}

export { getCallUserDetails, getNumberOfPhonecalls, getAccountDetails, createCall, getRedicrectCall, getUserPreviousCalls, getAllCalls, toggleInterestingCall, searchQuery, getChartData }
