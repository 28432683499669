<template>
  <div class="text-center">
    <v-menu open-on-hover>
      <template v-slot:activator="{ props }">
        <v-btn
          v-bind="props"
          :color="getColor"
          dark
        >
          {{ getName }}
        </v-btn>
      </template>
      <v-list :bg-color="getColor" dark>
        <v-list-item key="activate" value="activate" @click="$emit('activate', {id: id})" :disabled="active">
          <v-list-item-title>Aktiven</v-list-item-title>
        </v-list-item>
        <v-list-item key="deactivate" value="deactivate" @click="$emit('deactivate', {id: id})" :disabled="!active">
          <v-list-item-title>Neaktiven</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'UpdateActive',
  props: {
    id: Number,
    data: Object
  },
  emits: ['activate', 'deactivate'],
  computed: {
    active () {
      return this.data.active
    },
    getName () {
      return this.data.consultant.name
    },
    getColor () {
      if (this.data.active) {
        return 'green'
      } else {
        return 'red'
      }
    }
  }
}
</script>
