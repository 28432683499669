<template>
  <div class="form">
    <!-- <form v-on:submit="handleLogin">
      <div class="form-group">
        <label for="username">Username</label>
        <input type="text" class="form-control" id="username" v-model="username" />
      </div>
      <div class="form-group">
        <label for="password">Password</label>
        <input type="password" class="form-control" id="password" v-model="password" />
      </div>
      <button type="submit" class="btn btn-primary">Login</button>
    </form> -->
    <span v-if="this.errorMessage">{{ this.errorMessage }}</span>
    <v-form v-on:submit="handleLogin">
      <v-text-field
        v-model="username"
        id="username"
        label="Username"
      ></v-text-field>
      <v-text-field
        v-model="password"
        id="password"
        label="Password"
        type="password">
      </v-text-field>
      <v-btn type="submit">Login</v-btn>
    </v-form>
  </div>
</template>

<style scoped>
.form {
  min-width: 275px;
  width: calc(100% - 2rem);
  /* max-width: 500px; */
}
button.v-btn {
    background: var(--accent);
    color: #fff;
    font-weight: 400;
    letter-spacing: 1px;
    font-size: 1rem;
    min-width: 150px;
}
</style>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'LoginForm',
  data () {
    return {
      username: '',
      password: '',
      nextPath: '/',
      errorMessage: ''
    }
  },
  mounted () {
    this.updateAfterLoginNextPath()
  },
  methods: {
    handleLogin (event) {
      event.preventDefault()
      this.login({ username: this.username, password: this.password })
        .then(() => {
          this.$router.push(this.nextPath)
        })
        .catch((error) => {
          this.errorMessage = error.response.data.detail
        })
    },
    updateAfterLoginNextPath () {
      if ('next' in this.$route.query) {
        this.nextPath = this.$route.query.next
      }
    },
    ...mapActions([
      'login'
    ])
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
form {
  max-width:400px;
  margin: 0 auto;
}
</style>
