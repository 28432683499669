import axios from 'axios'

// Import configs from config.js file
import config from './config.js'

const BASE_URL = config.baseURL

const ACCESS_TOKEN = config.accessToken
const REFRESH_TOKEN = config.refreshToken

/**
 * Axios instance for making requests to the API for token.
 */
const tokenRequest = axios.create({
  baseURL: BASE_URL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
    accept: 'application/json'
  }
})

/**
 * Login a user
 * @param {string} username - The username of the user.
 * @param {string} password - The password of the user.
 * @returns {Promise} A promise that resolves with the response data.
 */
const loginUser = async (username, password) => {
  const loginBody = { username, password, timeout: 12000 }
  return tokenRequest.post('/api/token/', loginBody)
    .then((response) => {
      window.localStorage.setItem(ACCESS_TOKEN, response.data.access)
      window.localStorage.setItem(REFRESH_TOKEN, response.data.refresh)
      return Promise.resolve(response.data)
    })
    .catch((error) => {
      // console.log(error)
      return Promise.reject(error)
    })
}

/**
 * Refresh the access token
 * @returns {Promise} A promise that resolves with the response data.
 */
const refreshToken = async () => {
  const refreshBody = { refresh: window.localStorage.getItem(REFRESH_TOKEN), timeout: 12000 }
  return tokenRequest.post('/api/token/refresh/', refreshBody)
    .then((response) => {
      window.localStorage.setItem(ACCESS_TOKEN, response.data.access)
      return Promise.resolve(response.data)
    })
    .catch((error) => {
      // console.log(error)
      return Promise.reject(error)
    })
}

/**
 * Check if the error is a 401 error
 * @param {Number} status - The status code of the error response.
 * @returns {Boolean} True if the error is a 401 error.
 */
const isCorrectRefreshError = (status) => status === 401

/**
 * Axios instance for making requests to the API for auth.
 */
const authRequest = axios.create({
  baseURL: BASE_URL,
  timeout: 120000,
  headers: {
    Authorization: `Bearer ${window.localStorage.getItem(ACCESS_TOKEN)}`,
    'Content-Type': 'application/json'
  }
})

/**
 * Logout a user
 */
const logoutUser = () => {
  authRequest.defaults.headers.Authorization = ''
  window.localStorage.removeItem(ACCESS_TOKEN)
  window.localStorage.removeItem(REFRESH_TOKEN)
  window.location.href = '/'
}

/**
 * Error interceptor for authRequest
 * @param {Object} error - The error object.
 * @returns {Promise} A promise that resolves with the response data.
 */
const errorInterceptor = async (error) => {
  console.log('errorInterceptor START')
  console.log(error)
  if (typeof error === 'undefined') return Promise.reject(new Error('Unknown: errorInterceptor - error is null'))
  console.log('errorInterceptor 1')
  const originalRequest = error?.config
  const { status } = error?.response ?? { status: null }
  if (status && isCorrectRefreshError(status)) {
    console.log('errorInterceptor 2')
    return refreshToken()
      .then(() => {
        console.log('errorInterceptor 2.1')
        const headerAuthorization = `Bearer ${window.localStorage.getItem(ACCESS_TOKEN)}`
        authRequest.defaults.headers.Authorization = headerAuthorization
        return Promise.resolve(authRequest.request({
          method: originalRequest.method,
          url: originalRequest.url,
          data: originalRequest.data,
          params: originalRequest.params
        }))
      })
      .catch((tokenRefreshError) => {
        console.log('errorInterceptor 2.2')
        logoutUser()
        return Promise.reject(tokenRefreshError)
      })
  }
  console.log('errorInterceptor END')
  return Promise.reject(error ?? new Error('Unknown error'))
}

/**
 * Add the error interceptor to the authRequest instance
 */
authRequest.interceptors.response.use(
  async (response) => {
    console.log('response NOT ERROR - ' + response.status)
    return response
  },
  async (error) => {
    console.log('response ERROR - ' + error.status)
    errorInterceptor(error)
  }
)

export {
  tokenRequest, loginUser, logoutUser, refreshToken, authRequest,
  errorInterceptor, BASE_URL, ACCESS_TOKEN, REFRESH_TOKEN
}
