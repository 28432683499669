<script>
import router from '@/router'
import store from '@/store'
// import { mapActions } from 'vuex'

export default {
  name: 'LogoutView',
  beforeRouteEnter (to, from, next) {
    store.dispatch('logout')
    router.push('/login')
  },
  methods: {
    // ...mapActions([
    //   'logout'
    // ])
  }
}
</script>
