<template>
  <span class="consultant" :id="id">{{ name }}</span>
</template>

<script>
export default {
  name: 'ConsSpan',
  props: {
    id: Number,
    name: String
  }
}
</script>
