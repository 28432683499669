import { authRequest, ACCESS_TOKEN } from './auth'

/**
 * Get all consultants
 * @returns {Promise} A promise that resolves with the response data.
 */
const getConsultants = () => {
  authRequest.defaults.headers.Authorization = `Bearer ${window.localStorage.getItem(ACCESS_TOKEN)}`
  authRequest.defaults.headers['Content-Type'] = 'application/json'
  return authRequest.get('/api/asconf/consultants/')
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error))
}
/**
 * Get one consultant
 * @param {Number} id - The id of the consultant to get.
 * @returns {Promise} A promise that resolves with the response data.
 */
const getOneConsultant = (id) => {
  authRequest.defaults.headers.Authorization = `Bearer ${window.localStorage.getItem(ACCESS_TOKEN)}`
  authRequest.defaults.headers['Content-Type'] = 'application/json'
  return authRequest.get(`/api/asconf/consultants/${id}/`)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error))
}
/**
 * Post a new consultant
 * @param {Object} data - The data to post.
 * @returns {Promise} A promise that resolves with the response data.
 */
const postConsultants = (data) => {
  authRequest.defaults.headers.Authorization = `Bearer ${window.localStorage.getItem(ACCESS_TOKEN)}`
  authRequest.defaults.headers['Content-Type'] = 'multipart/form-data'
  return authRequest.post('/api/asconf/consultants/', data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error))
}
/**
 * Put a consultant
 * @param {Number} id - The id of the consultant to put.
 * @param {Object} data - The data to put.
 * @returns {Promise} A promise that resolves with the response data.
 */
const putConsultants = (id, data) => {
  authRequest.defaults.headers.Authorization = `Bearer ${window.localStorage.getItem(ACCESS_TOKEN)}`
  authRequest.defaults.headers['Content-Type'] = 'multipart/form-data'
  return authRequest.put(`/api/asconf/consultants/${id}/`, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error))
}
/**
 * Delete a consultant
 * @param {Number} id - The id of the consultant to delete.
 * @returns {Promise} A promise that resolves with the response data.
 */
const deleteConsultants = (id) => {
  authRequest.defaults.headers.Authorization = `Bearer ${window.localStorage.getItem(ACCESS_TOKEN)}`
  authRequest.defaults.headers['Content-Type'] = 'application/json'
  return authRequest.delete(`/api/asconf/consultants/${id}/`)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error))
}
/**
 * Get default consultants
 * @returns {Promise} A promise that resolves with the response data.
 */
const getDefaultConsultants = () => {
  authRequest.defaults.headers.Authorization = `Bearer ${window.localStorage.getItem(ACCESS_TOKEN)}`
  authRequest.defaults.headers['Content-Type'] = 'application/json'
  return authRequest.get('/api/asconf/def-cons/')
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error))
}
/**
 * Put default consultants
 * @param {Number} id - The id of the default consultant to put.
 * @param {Object} data - The data to put.
 * @returns {Promise} A promise that resolves with the response data.
 */
const putDefaultConsultants = (id, data) => {
  authRequest.defaults.headers.Authorization = `Bearer ${window.localStorage.getItem(ACCESS_TOKEN)}`
  authRequest.defaults.headers['Content-Type'] = 'application/json'
  return authRequest.put(`/api/asconf/def-cons/${id}/`, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error))
}
/**
 * Delete default consultants
 * @param {Number} id - The id of the default consultant to delete.
 * @returns {Promise} A promise that resolves with the response data.
 */
const deleteDefaultConsultants = (id) => {
  authRequest.defaults.headers.Authorization = `Bearer ${window.localStorage.getItem(ACCESS_TOKEN)}`
  authRequest.defaults.headers['Content-Type'] = 'application/json'
  return authRequest.delete(`/api/asconf/def-cons/${id}/`)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error))
}

export { getConsultants, getOneConsultant, postConsultants, putConsultants, deleteConsultants, getDefaultConsultants, putDefaultConsultants, deleteDefaultConsultants }
