<template>
  <v-row dense style="flex-flow: column nowrap !important;">
    <v-radio-group class="radio-list" style="flex-flow: column nowrap !important;" v-model="checkbox">
      <v-col>
        <v-card title="Računovodstvo" min-height="100%" variant="outlined" style="padding-bottom: 0.5rem;">
          <h3 class="title-label"><span>01</span>Računovodstvo in knjigovodstvo</h3>
          <div class="subtitle-label-outer" @click="labelClick">
            <h3 class="subtitle-label">Zasebni sektor</h3>
            <v-radio
            v-model="checkbox"
            :value="101"
            @click="this.checkbox = 101; $emit('classification', { id: 101, name: '[Zasebni sektor]' })"
          />
          </div>
          <div class="subtitle-label-outer" @click="labelClick">
            <h3 class="subtitle-label">Javni sektor</h3>
            <v-radio
            v-model="checkbox"
            :value="102"
            @click="this.checkbox = 102; $emit('classification', { id: 102, name: '[Javni sektor]' })"
          />
          </div>
          <div class="big-label-outer mt-3" @click="labelClick">
            <h3 class="title-label">
              <span>02</span>Javna objava
            </h3>
            <v-radio
              v-model="checkbox"
              :value="120"
              class="big-label"
              @click="this.checkbox = 120; $emit('classification', { id: 120, name: '[Javna objava]' })"
            />
          </div>
          <div class="big-label-outer" @click="labelClick">
            <h3 class="title-label">
              <span>03</span>Revidiranje, nadziranje
            </h3>
            <v-radio
            v-model="checkbox"
            :value="130"
            class="big-label"
            @click="this.checkbox = 130; $emit('classification', { id: 130, name: '[Revidiranje, nadziranje]' })"
          />
          </div>
        </v-card>
      </v-col>
      <v-col>
        <v-card title="Davščine" min-height="100%" variant="outlined" style="padding-bottom: 1rem;">
          <h3 class="title-label">
            <span>01</span>Davek na dodano vrednost in drugi prometni davki
          </h3>
          <div class="subtitle-label-outer" @click="labelClick">
            <h3 class="subtitle-label">Davek na dodano vrednost</h3>
            <v-radio
            v-model="checkbox"
            :value="211"
            @click="this.checkbox = 211; $emit('classification', { id: 211, name: '[Davek na dodano vrednost]' })"
          />
          </div>
          <div class="subtitle-label-outer" @click="labelClick">
            <h3 class="subtitle-label">Drugi prometni davki oziroma dajatve</h3>
            <v-radio
            v-model="checkbox"
            :value="212"
            @click="this.checkbox = 212; $emit('classification', { id: 212, name: '[Drugi prometni davki oziroma dajatve]' })"
          />
          </div>
          <div class="subtitle-label-outer" @click="labelClick">
            <h3 class="subtitle-label">Trošarine</h3>
            <v-radio
            v-model="checkbox"
            :value="213"
            @click="this.checkbox = 213; $emit('classification', { id: 213, name: '[Trošarine]' })"
          />
          </div>
          <h3 class="title-label mt-3"><span>02</span>Davki pravnih oseb</h3>
          <div class="subtitle-label-outer" @click="labelClick">
            <h3 class="subtitle-label">Davek od dohodkov pravnih oseb</h3>
            <v-radio
            v-model="checkbox"
            :value="221"
            @click="this.checkbox = 221; $emit('classification', { id: 221, name: '[Davek od dohodkov pravnih oseb]' })"
          />
          </div>
          <div class="subtitle-label-outer" @click="labelClick">
            <h3 class="subtitle-label">Drugi davki pravnih oseb</h3>
            <v-radio
            v-model="checkbox"
            :value="222"
            @click="this.checkbox = 222; $emit('classification', { id: 222, name: '[Drugi davki pravnih oseb]' })"
          />
          </div>
          <h3 class="title-label mt-3"><span>03</span>Davki fizičnih oseb</h3>
          <div class="subtitle-label-outer" @click="labelClick">
            <h3 class="subtitle-label">Dohodnina</h3>
            <v-radio
            v-model="checkbox"
            :value="231"
            @click="this.checkbox = 231; $emit('classification', { id: 231, name: '[Dohodnina]' })"
          />
          </div>
          <div class="subtitle-label-outer" @click="labelClick">
            <h3 class="subtitle-label">Drugi davki fizičnih oseb</h3>
            <v-radio
            v-model="checkbox"
            :value="232"
            @click="this.checkbox = 232; $emit('classification', { id: 232, name: '[Drugi davki fizičnih oseb]' })"
          />
          </div>
          <div class="big-label-outer mt-3" @click="labelClick">
            <h3 class="title-label">
              <span>04</span>Prispevki za socialno varnost
            </h3>
            <v-radio
            v-model="checkbox"
            :value="240"
            class="big-label"
            @click="this.checkbox = 240; $emit('classification', { id: 240, name: '[Prispevki za socialno varnost]' })"
          />
          </div>
          <div class="big-label-outer" @click="labelClick">
            <h3 class="title-label">
              <span>05</span>Druge davščine
            </h3>
            <v-radio
            v-model="checkbox"
            :value="250"
            class="big-label"
            @click="this.checkbox = 250; $emit('classification', { id: 250, name: '[Druge davščine]' })"
          />
          </div>
          <div class="big-label-outer" @click="labelClick">
            <h3 class="title-label">
              <span>06</span>Meddržavne pogodbe
            </h3>
            <v-radio
            v-model="checkbox"
            :value="260"
            class="big-label"
            @click="this.checkbox = 260; $emit('classification', { id: 260, name: '[Meddržavne pogodbe]' })"
          />
          </div>
          <h3 class="title-label">
            <span>07</span>Davčni postopek in davčna služba ter poravnava davkov
          </h3>
          <div class="subtitle-label-outer" @click="labelClick">
            <h3 class="subtitle-label">Davčni postopek</h3>
            <v-radio
            v-model="checkbox"
            :value="271"
            @click="this.checkbox = 271; $emit('classification', { id: 271, name: '[Davčni postopek]' })"
          />
          </div>
          <div class="subtitle-label-outer" @click="labelClick">
            <h3 class="subtitle-label">Davčna služba</h3>
            <v-radio
            v-model="checkbox"
            :value="272"
            @click="this.checkbox = 272; $emit('classification', { id: 272, name: '[Davčna služba]' })"
          />
          </div>
        </v-card>
      </v-col>
      <v-col>
        <v-card
          title="Povezani predpisi"
          min-height="100%"
          variant="outlined"
          style="padding-bottom: 1rem;"
        >
        <div class="big-label-outer" @click="labelClick">
            <h3 class="title-label">
              <span>01</span>Statusno pravo in status subjektov
            </h3>
            <v-radio
            v-model="checkbox"
            :value="310"
            class="big-label"
            @click="this.checkbox = 310; $emit('classification', { id: 310, name: '[Statusno pravo in status subjektov]' })"
          />
          </div>
        <div class="big-label-outer" @click="labelClick">
            <h3 class="title-label">
              <span>02</span>Sanacija, prisilna poravnava, stečaj, likvidacija
            </h3>
            <v-radio
            v-model="checkbox"
            :value="320"
            class="big-label"
            @click="this.checkbox = 320; $emit('classification', { id: 320, name: '[Sanacija, prisilna poravnava, stečaj, likvidacija]' })"
          />
          </div>
        <div class="big-label-outer" @click="labelClick">
            <h3 class="title-label">
              <span>03</span>Obligacijska razmerja
            </h3>
            <v-radio
            v-model="checkbox"
            :value="330"
            class="big-label"
            @click="this.checkbox = 330; $emit('classification', { id: 330, name: '[Obligacijska razmerja]' })"
          />
          </div>
        <div class="big-label-outer" @click="labelClick">
            <h3 class="title-label">
              <span>04</span>Javne finance
            </h3>
            <v-radio
            v-model="checkbox"
            :value="340"
            class="big-label"
            @click="this.checkbox = 340; $emit('classification', { id: 340, name: '[Javne finance]' })"
          />
          </div>
        <div class="big-label-outer skip">
            <h3 class="title-label">
              <span>05</span>Finančno poslovanje in plačilni promet
            </h3>
          </div>
          <div class="subtitle-label-outer" @click="labelClick">
            <h3 class="subtitle-label">Finančno poslovanje</h3>
            <v-radio
            v-model="checkbox"
            :value="351"
            @click="this.checkbox = 351; $emit('classification', { id: 351, name: '[Finančno poslovanje]' })"
          />
          </div>
          <div class="subtitle-label-outer" @click="labelClick">
            <h3 class="subtitle-label">Plačilni promet</h3>
            <v-radio
            v-model="checkbox"
            :value="352"
            @click="this.checkbox = 352; $emit('classification', { id: 352, name: '[Plačilni promet]' })"
          />
          </div>
          <div class="subtitle-label-outer" @click="labelClick">
            <h3 class="subtitle-label">Denarni, kreditni in bančni sistem</h3>
            <v-radio
            v-model="checkbox"
            :value="353"
            @click="this.checkbox = 353; $emit('classification', { id: 353, name: '[Denarni, kreditni in bančni sistem]' })"
          />
          </div>
          <div class="big-label-outer mt-3 skip">
            <h3 class="title-label">
              <span>06</span>Delovno pravo in kolektivne pogodbe
            </h3>
          </div>
          <div class="subtitle-label-outer" @click="labelClick">
            <h3 class="subtitle-label">Delovno pravo</h3>
            <v-radio
            v-model="checkbox"
            :value="361"
            @click="this.checkbox = 361; $emit('classification', { id: 361, name: '[Delovno pravo]' })"
          />
          </div>
          <div class="subtitle-label-outer" @click="labelClick">
            <h3 class="subtitle-label">Kolektivne pogodbe</h3>
            <v-radio
            v-model="checkbox"
            :value="362"
            @click="this.checkbox = 362; $emit('classification', { id: 362, name: '[Kolektivne pogodbe]' })"
          />
          </div>
          <div class="big-label-outer mt-3 skip">
            <h3 class="title-label">
              <span>07</span>Drugi delovni predpisi
            </h3>
          </div>
          <div class="subtitle-label-outer" @click="labelClick">
            <h3 class="subtitle-label">Avtorsko pravo</h3>
            <v-radio
            v-model="checkbox"
            :value="371"
            @click="this.checkbox = 371; $emit('classification', { id: 371, name: '[Avtorsko pravo]' })"
          />
          </div>
          <div class="subtitle-label-outer" @click="labelClick">
            <h3 class="subtitle-label">Stvarnostna razmerja</h3>
            <v-radio
            v-model="checkbox"
            :value="372"
            @click="this.checkbox = 372; $emit('classification', { id: 372, name: '[Stvarnostna razmerja]' })"
          />
          </div>
          <div class="subtitle-label-outer" @click="labelClick">
            <h3 class="subtitle-label">Klasifikacija</h3>
            <v-radio
            v-model="checkbox"
            :value="373"
            @click="this.checkbox = 373; $emit('classification', { id: 373, name: '[Klasifikacija]' })"
          />
          </div>
          <div class="subtitle-label-outer" @click="labelClick">
            <h3 class="subtitle-label">Gradbeništvo</h3>
            <v-radio
            v-model="checkbox"
            :value="374"
            @click="this.checkbox = 374; $emit('classification', { id: 374, name: '[Gradbeništvo]' })"
          />
          </div>
          <div class="subtitle-label-outer" @click="labelClick">
            <h3 class="subtitle-label">Trgovina</h3>
            <v-radio
            v-model="checkbox"
            :value="375"
            @click="this.checkbox = 375; $emit('classification', { id: 375, name: '[Trgovina]' })"
          />
          </div>
          <div class="subtitle-label-outer" @click="labelClick">
            <h3 class="subtitle-label">Gostinstvo</h3>
            <v-radio
            v-model="checkbox"
            :value="376"
            @click="this.checkbox = 376; $emit('classification', { id: 376, name: '[Gostinstvo]' })"
          />
          </div>
        </v-card>
      </v-col>
    </v-radio-group>
  </v-row>
</template>

<script>
export default {
  name: 'ClassificationForm',
  data () {
    return {
      name: 'ClassificationForm',
      checkbox: ''
    }
  },
  methods: {
    labelClick (ev) {
      if (!ev) return
      ev.preventDefault()
      ev.stopPropagation()
      let outer = ev.target.closest('.subtitle-label-outer')
      if (!outer) outer = ev.target.closest('.big-label-outer')
      const input = outer.querySelector('input')
      if (input) {
        input.click()
      }
    }
  },
  emits: ['classification']
}
</script>

<style>
.big-label .v-label {
  font-weight: bold;
  opacity: 1;
  font-size: 0.5rem;
  width: 89%;
}
.radio-list label {
  font-size: 0.5rem;
}

.title-label {
  font-size: 0.8rem;
  width: 89%;
}

div.radio-list > .v-input__control > .v-selection-control-group {
  flex-direction: column;
  justify-content: center;
}
.big-label-outer, .subtitle-label-outer {
  display: flex; flex-flow: row nowrap; align-items: center;
  justify-content: space-between;
}
.title-label {
  opacity: 0.9;
}
.title-label,
.subtitle-label {
  line-height: 1.25;
  font-size: 0.875rem !important;
  display: flex; align-items: center; justify-content: flex-start; text-align: left; gap: 10px;
}
.title-label > span {
  font-size: 1.25rem;
  line-height: 1.25;
  color: var(--accent);
}
h3.title-label {
  padding: 0.5rem 0.75rem;
}
.subtitle-label-outer {
  width: 100%;
  justify-content: space-between;
  cursor: pointer;
  transition: all 0.2s ease-out;
}
.big-label-outer:not(.skip) {
  cursor: pointer;
  transition: all 0.2s ease-out;
}
.subtitle-label-outer:hover, .big-label-outer:not(.skip):hover {
  background: var(--bg3);
}
.subtitle-label {
  font-size: 0.875rem !important;
  font-weight: 400;
  padding-left: 50px;
  padding: 0.5rem 0 0.5rem 3rem;
  opacity: 0.85;
}
.subtitle-label-outer .v-radio {
  padding-right: 26px;
  /* max-height: 32px; */
}
.v-radio {
  max-width: max-content !important;
}
/* input[type='radio'] {
  width: 0.5rem;
  height: 0.5rem;
} */
</style>
