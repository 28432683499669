import { createRouter, createWebHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import LoginView from '../views/LoginView.vue'
import AuthenticatedPing from '../views/AuthenticatedPing.vue'
import LogoutView from '../views/LogoutView.vue'
import ScheduleView from '../views/ScheduleView.vue'
import AdView from '../views/AdView.vue'
import ConsultingView from '../views/ConsultingView.vue'
import { ACCESS_TOKEN, REFRESH_TOKEN, refreshToken } from '@/services/api/auth'

const PUBLIC_PATHS = ['/about', '/login']

const routes = [
  {
    path: '/',
    redirect: 'queue'
    // name: 'home',
    // component: HomeView,
    // meta: { title: 'Home' }
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView,
    meta: { title: 'About' }
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: { title: 'Login' }
  },
  {
    path: '/logout',
    name: 'logout',
    component: LogoutView,
    meta: { title: 'Logout' }
  },
  {
    path: '/ping',
    name: 'ping',
    component: AuthenticatedPing,
    meta: { title: 'Ping' }
  },
  {
    path: '/schedule',
    name: 'schedule',
    component: ScheduleView,
    meta: { title: 'Schedule' }
  },
  {
    path: '/queue',
    name: 'queue',
    component: ConsultingView,
    meta: { title: 'Queue' }
  },
  {
    path: '/ad',
    name: 'ad',
    component: AdView,
    meta: { title: 'Ad' }
  }
]

/**
 * Create the router. Use the history mode.
 */
const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHistory(),
  routes
})

/**
 * Check if the path is not public and the user is not logged in.
 * @param {string} path - The path to check.
 * @returns {boolean} True if the path is not public and the user is not logged in.
 */
const unAuthorizedAndPrivatePage = (path) => (
  !PUBLIC_PATHS.includes(path) &&
  !(ACCESS_TOKEN in window.localStorage) &&
  !(REFRESH_TOKEN in window.localStorage)
)

router.beforeEach((to, from, next) => {
  if (unAuthorizedAndPrivatePage(to.path)) {
    document.title = 'ZRFR Login'
    next(`/login?next=${to.path}`)
  } else {
    document.title = 'ZRFR ' + (to.meta.title || '404')
    refreshToken().then(
      // console.log('SUCCESS - refreshToken')
    ).catch((err) => {
      console.log('ERROR - refreshToken [ConsultingView]')
      console.log(err)
    })
    next()
  }
})

export default router
