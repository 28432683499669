<template>
  <v-container class="container" grid-list-lg>
    <v-row>
      <v-col cols="12">
        <h1>Svetovalci</h1>
      </v-col>
    </v-row>
    <v-row v-if="loading">
      <v-col cols="12">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </v-col>
    </v-row>
    <v-row v-if="error">
      <v-col cols="12">
        <v-alert type="error" :value="true" closable>
          {{ error }}
        </v-alert>
      </v-col>
    </v-row>
    <v-row v-if="server_data">
      <v-card variant="tonal" width="100%" class="text-left">
        <template v-slot:text>
          <v-row no-gutters>
            <v-col cols="12">
              Danes - <span class="font-weight-bold">{{ today }}</span>
            </v-col>
          </v-row>
          <v-row align="start" dense>
            <v-col cols="auto" v-for="cons in consultantsToday" :key="cons.id">
              <UpdateActive
                :id="cons.id"
                :data="cons"
                @activate="activateCons"
                @deactivate="deactivateCons"
              ></UpdateActive>
            </v-col>
          </v-row>
          <v-row align="start" dense no-gutters>
            <v-col cols="auto">
              <v-checkbox
                label="Vsi svetovalci so danes odsotni."
                hide-details
                density="compact"
                v-model="server_data.today[0].all_inactive"
                @change="toggleActiveCons"
              ></v-checkbox>
            </v-col>
          </v-row>
        </template>
      </v-card>
      <v-card variant="tonal" width="100%" class="text-left mt-3">
        <template v-slot:text>
          <v-row>
            <v-col cols="12">
              <v-table id="schedule">
                <thead>
                  <tr>
                    <th class="text-left">Dan</th>
                    <th class="text-left">Svetovalci</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="schedule in server_data.schedule"
                    :key="schedule.dow"
                  >
                  <td class="text-left">{{ dayFromNumber(schedule.dow) }}</td>
                  <td class="text-left">
                    <v-row>
                      <v-col
                        v-for="cons in schedule.consultants"
                        :key="cons.id"
                        cols="auto"
                      >
                        <component
                          :is="currentConsType"
                          :id="cons.consultant.id"
                          :name="(cons.consultant.id !== cons.def_consultant.id ? (cons.consultant.id ? cons.consultant.name : 'Ni svetovalca') + ' nam. ' + cons.def_consultant.name : cons.consultant.name)"
                          @click-chip="openConsEditor('edit', cons.id, schedule.dow, cons.consultant.id, cons.consultant.name)"
                          @close="removeConsFromSchedule(cons.id)"
                        ></component>
                      </v-col>
                      <v-col v-if="showAddButton" class="d-flex align-end flex-column">
                        <v-btn
                          color="primary"
                          icon="mdi-plus"
                          size="x-small"
                          @click="openConsEditor('add', null, schedule.dow)"
                        >
                        </v-btn>
                      </v-col>
                    </v-row>
                  </td>
                </tr>
                </tbody>
              </v-table>
            </v-col>
          </v-row>
          <v-row align="start" dense>
            <v-col cols="auto">
              <v-btn variant="outlined" @click="toggleEditable()">
                {{ texts.edit_schedule }}
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn variant="outlined" @click="toggleEditConsultant()">
                Uredi svetovalce
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </v-card>
    </v-row>
  </v-container>
  <!-- Dialog for editing scheduled consultant -->
  <v-dialog
    v-model="editSchedule.isActive"
    transition="dialog-top-transition"
    max-width="80%"
  >
    <v-card>
      <v-toolbar
        color="primary"
        dark
        flat
        :title="editSchedule.name"
      ></v-toolbar>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-select
                v-if="consultants"
                v-model="currConsultant"
                :items="consultants"
                item-title="name"
                item-value="id"
                label="Svetovalci"
                clearable
                @click:clear="clearConsultant"
                hide-details
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          color="primary"
          text
          @click="saveConsEditor()"
        >
          Shrani
        </v-btn>
        <v-btn
          color="secondary"
          text
          @click="editSchedule.isActive = false"
        >
          Zapri
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog
    v-model="editConsultants.isActive"
    fullscreen
    :scrim="false"
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar
        color="primary"
        dark
        flat
      >
        <v-btn
          icon
          dark
          @click="editConsultants.isActive = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Uredi Svetovalce</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          variant = "outlined"
          @click="openConsSubDialog('add')"
        >
          <v-icon>mdi-plus</v-icon> Dodaj svetovalca
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-row v-if="consultants">
            <v-col cols="12">
              <v-table>
                <thead>
                  <tr>
                    <th class="text-left">Svetovalec</th>
                    <th class="text-left">Queue</th>
                    <th class="text-left">Pot</th>
                    <th class="text-left">Akcija</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="cons in consultants"
                    :key="cons.id"
                  >
                    <td class="text-left">{{ cons.name }}</td>
                    <td class="text-left">{{ cons.ext }}</td>
                    <td class="text-left">{{ cons.path }}</td>
                    <td>
                      <v-btn
                        color="primary"
                        icon="mdi-pencil"
                        size="x-small"
                        @click="openConsSubDialog('edit', cons.id)"></v-btn>&nbsp;
                      <!-- <v-btn
                        color="primary"
                        icon="mdi-delete"
                        size="x-small"></v-btn> -->
                    </td>
                  </tr>
                </tbody>
              </v-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
  <v-dialog
    v-model="editConsultants.subDialog.isActive"
    transition="dialog-top-transition"
    max-width="80%"
    @update:modelValue="closeConsSubDialog"
  >
    <v-card>
      <v-toolbar
        color="primary"
        dark
        flat
        :title="editConsultants.subDialog.dialogName"
      ></v-toolbar>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <AddConsultantForm
                v-if="editConsultants.subDialog.dialogType === 'add'"
                type="add"
                @add-consultant="onEditOrAddConsultant"
                >
              </AddConsultantForm>
              <AddConsultantForm
                v-if="editConsultants.subDialog.consultant"
                type="edit"
                :consultant="editConsultants.subDialog.consultant"
                @edit-consultant="onEditOrAddConsultant"
              ></AddConsultantForm>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { getSchedule, postSchedule, putSchedule } from '@/services/api/schedule'
import { getConsultants, putDefaultConsultants, deleteDefaultConsultants } from '@/services/api/consultants'
import UpdateActive from '@/components/UpdateActive'
import ConsSpan from '@/components/ConsSpan'
import ConsChip from '@/components/ConsChip'
import AddConsultantForm from '@/components/AddConsultantForm'

export default {
  name: 'ScheduleView',
  data () {
    return {
      name: 'Schedule',
      dow: ['Ponedeljek', 'Torek', 'Sreda', 'Četrtek', 'Petek', 'Sobota', 'Nedelja'],
      loading: false,
      server_data: null,
      error: null,
      consultants: [],
      currConsultant: { id: null, name: 'Ni svetovalca' },
      currentConsType: 'ConsSpan',
      showAddButton: false,
      editSchedule: {
        isActive: false,
        consId: null,
        dow: null,
        name: null,
        type: null
      },
      editConsultants: {
        isActive: false,
        subDialog: {
          isActive: false,
          dialogName: null,
          dialogType: null,
          consultant: null
        }
      },
      texts: {
        edit_schedule: 'Uredi spored'
      }
    }
  },
  created () {
    this.$watch(
      () => this.$route.params,
      () => {
        this.load_schedule()
      },
      { immediate: true }
    )
  },
  components: {
    UpdateActive,
    ConsSpan,
    ConsChip,
    AddConsultantForm
  },
  computed: {
    today () {
      return this.dow[this.server_data.today[0].dow - 1]
    },
    consultantsToday () {
      return this.server_data.today[0].consultants.filter(c => c.consultant.name != null)
    }
  },
  methods: {
    /**
     * Load schedule from server and set it to server_data
     */
    load_schedule () {
      this.loading = true
      this.server_data = null
      getSchedule()
        .then((response) => {
          this.server_data = response
          this.loading = false
        })
        .catch((error) => {
          this.error = error
        })
    },
    /**
     * Load consultants from server and set it to consultants
     */
    load_consultants () {
      this.consultants = []
      getConsultants()
        .then((response) => {
          this.consultants = this.consultants.concat(response.consultants)
        })
        .catch((error) => {
          this.error = error
        })
    },
    /**
     * Get day string from day of week number
     * @param {Number} number day of week number
     * @returns {String} day of week string
     */
    dayFromNumber (number) {
      return this.dow[number - 1]
    },
    /**
     * Toggle table elements to be editable (switching between ConsSpan and ConsChip components)
    */
    toggleEditable () {
      if (this.currentConsType === 'ConsSpan') {
        this.currentConsType = 'ConsChip'
        this.showAddButton = true
        this.texts.edit_schedule = 'Prekini urejanje'
      } else {
        this.currentConsType = 'ConsSpan'
        this.showAddButton = false
        this.texts.edit_schedule = 'Uredi spored'
      }
    },
    /**
     * Activate consultant; POST request to server with consultant id and activate to true
     */
    activateCons ({ id }) {
      postSchedule({ id: id, activate: true })
        .then((response) => {
          this.load_schedule()
        })
        .catch((error) => {
          this.error = error
        })
    },
    /**
     * Deactivate consultant; POST request to server with consultant id and deactivate to true
     * @param {Object} id consultant id
     */
    deactivateCons ({ id }) {
      postSchedule({ id: id, deactivate: true })
        .then((response) => {
          this.load_schedule()
        })
        .catch((error) => {
          this.error = error
        })
    },
    /**
     * Toggle both consultantns; POST request to server wtih all_inactive value
     */
    toggleActiveCons () {
      postSchedule({ all_inactive: this.server_data.today[0].all_inactive })
        .then((response) => {
          this.load_schedule()
        })
        .catch((error) => {
          this.error = error
        })
    },
    /**
     * Open dialog for editing schedule consultants and adding existing consultants to schedule
     * @param {String} type type of dialog to open
     * @param {Number} consId consultant id
     * @param {Number} dow day of week number
     * @param {Number} currCons current consultant id
     * @param {String} currConsName name of consultant
     */
    openConsEditor (type = 'edit', consId = null, dow = null, currCons = null, currConsName = null) {
      this.load_consultants()
      if (currCons) {
        this.currConsultant = { id: currCons, name: currConsName }
      } else {
        this.currConsultant = { id: null, name: (type === 'edit' ? 'Ni svetovalca' : 'Dodaj svetovalca') }
      }
      if (type === 'edit') {
        this.editSchedule.name = 'Uredi svetovalca'
        this.editSchedule.consId = consId
        this.editSchedule.dow = dow
        this.editSchedule.isActive = true
        this.editSchedule.type = 'edit'
      } else if (type === 'add') {
        this.editSchedule.name = 'Dodaj svetovalca'
        this.editSchedule.consId = null
        this.editSchedule.dow = dow
        this.editSchedule.isActive = true
        this.editSchedule.type = 'add'
      }
    },
    /**
     * Save edited schedule or add new consultant to schedule
     */
    saveConsEditor () {
      this.editSchedule.isActive = false
      let id = null
      if (typeof this.currConsultant === 'object') {
        id = this.currConsultant.id
      } else if (typeof this.currConsultant === 'number') {
        id = this.currConsultant
      }

      if (this.editSchedule.type === 'edit') {
        putSchedule(this.editSchedule.consId, { consultant: id })
          .then((response) => {
            this.load_schedule()
          })
          .catch((error) => {
            this.error = error
          })
      } else if (this.editSchedule.type === 'add' && id !== null) {
        putDefaultConsultants(this.editSchedule.dow, { consultant: id })
          .then((response) => {
            this.load_schedule()
          })
          .catch((error) => {
            this.error = error
          })
      }
    },
    /**
     * Delete consultant from schedule
     * @param {Number} consId consultant id
     */
    removeConsFromSchedule (consId) {
      // console.log(consId)
      deleteDefaultConsultants(consId)
        .then((response) => {
          this.load_schedule()
        })
        .catch((error) => {
          this.error = error
        })
    },
    /**
     * Clear selected consultant
     */
    clearConsultant () {
      this.currConsultant = { id: null, name: 'Ni svetovalca' }
    },
    /**
     * Open dialog for viewing, editing or deleting consultant
     */
    toggleEditConsultant () {
      this.load_consultants()
      this.editConsultants.isActive = !this.editConsultants.isActive
    },
    /**
     * Open dialog for adding new consultant
     * @param {String} type type of dialog to open
     * @param {Number} id consultant id
     */
    openConsSubDialog (type = 'add', id = null) {
      this.editConsultants.subDialog.dialogType = type
      this.editConsultants.subDialog.isActive = true

      if (type === 'add') {
        this.editConsultants.subDialog.dialogName = 'Dodaj svetovalca'
      } else if (type === 'edit') {
        this.editConsultants.subDialog.dialogName = 'Uredi svetovalca'
        this.editConsultants.subDialog.consultant = this.consultants.find((cons) => cons.id === id)
      }
    },
    /**
     * Close dialog and clear data
     */
    closeConsSubDialog () {
      // this.editConsultants.subDialog.isActive = false
      this.editConsultants.subDialog.consultant = null
    },
    onEditOrAddConsultant ({ err, msg }) {
      console.log(err, msg)
      if (err) {
        this.error = err
      } else {
        this.load_consultants()
        this.editConsultants.subDialog.isActive = false
      }
    }
  }
}
</script>

<style scoped>
.v-table {
  background: none;
  color: var(--color);
}
.v-table td, .v-table th, .v-table tr {
  border-bottom: 1px solid var(--color-disabled) !important;
}
.v-table .v-table__wrapper > table > thead > tr > th {
  color: var(--color-disabled);
}
#schedule > .v-table__wrapper > table > thead > tr > th:first-child,
#schedule > .v-table__wrapper > table > tbody > tr > td:first-child {
  width: 20%;
}
#schedule {
  background: none;
  color: var(--color);
  margin-bottom: 1rem;
}
#schedule td.text-left:first-child {
  font-weight: 600 ;
}
#schedule td.text-left:last-child > .v-row {
  margin: 0 0 0 -12px !important;
}
#schedule .v-btn {
  background: var(--accent) !important;
}
.container {
  width: calc(100% - 2rem);
}
.container > div > .v-card {
  background: white;
}
.v-dialog .v-card {
  background: var(--bg2) !important;
  color: var(--color);
}
.v-dialog .v-toolbar {
  background: var(--accent) !important;
}
.v-card-actions .v-btn:first-child {
  color: var(--accent) !important;
}
.v-card-actions .v-btn:last-child {
  color: var(--color-disabled) !important;
}
.v-row + .v-row--dense {
  margin: 0.5rem 0 0.3rem 0;
}
.v-row + .v-row--dense > div:first-child {
  padding-left: 0;
}
.v-dialog table tr > th:last-child,
.v-dialog table tr > td:last-child {
  text-align: center !important;
}
.v-dialog table tr > td:first-child {
  font-weight: 600 !important;
}
</style>
