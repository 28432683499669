import { authRequest, ACCESS_TOKEN } from './auth'

/**
 * Get the schedule
 * @returns {Promise} A promise that resolves with the response data.
 */
const getSchedule = () => {
  authRequest.defaults.headers.Authorization = `Bearer ${window.localStorage.getItem(ACCESS_TOKEN)}`
  authRequest.defaults.headers['Content-Type'] = 'application/json'
  return authRequest.get('/api/asconf/schedule/')
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error))
}
/**
 * Post the schedule
 * @param {Object} data - The data to post.
 * @returns {Promise} A promise that resolves with the response data.
 */
const postSchedule = (data) => {
  authRequest.defaults.headers.Authorization = `Bearer ${window.localStorage.getItem(ACCESS_TOKEN)}`
  authRequest.defaults.headers['Content-Type'] = 'application/json'
  return authRequest.post('/api/asconf/schedule/', data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error))
}
/**
 * Put the schedule
 * @param {Number} id - The id of the schedule to put.
 * @param {Object} data - The data to put.
 * @returns {Promise} A promise that resolves with the response data.
 */
const putSchedule = (id, data) => {
  authRequest.defaults.headers.Authorization = `Bearer ${window.localStorage.getItem(ACCESS_TOKEN)}`
  authRequest.defaults.headers['Content-Type'] = 'application/json'
  return authRequest.put(`/api/asconf/schedule/${id}/`, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error))
}

export { getSchedule, postSchedule, putSchedule }
