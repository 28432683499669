<template>
<div>
  <v-container grid-list-lg style="max-width: 100% !important">
    <v-row class="pb-4">
      <v-col cols="12">
        <h1>Svetovanje</h1>
        <v-col> Število današnjih zaključenih svetovanj:  {{ this.consults_today }} </v-col>
      </v-col>
    </v-row>
    <v-card variant="tonal" width="100%" class="text-left pa-4">
      <v-row>
        <v-col style="padding:0 0 0.75rem 0;">
          <v-radio-group class="radio-list" v-model="callType" hide-details="true">
            <v-radio class="radio-button" label="Uporabnik" :value="'contact'"> </v-radio>
            <v-radio class="radio-button" label="Naročnik" :value="'account'"> </v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-table style="position: relative">
        <thead>
          <tr>
            <th class="text-subtitle-2">#</th>
            <th class="text-subtitle-2">TELEFONSKA ŠTEVILKA</th>
            <th class="text-subtitle-2">RFR ŠT.</th>
            <th class="text-subtitle-2">OSEBA</th>
            <th class="text-subtitle-2">NAROČNIK</th>
            <th class="text-subtitle-2">ČAS ČAKANJA</th>
            <th class="text-subtitle-2">ŠT. SVETOVANJ</th>
            <th class="text-subtitle-2">ČAS SVETOVANJA</th>
            <th class="text-subtitle-2" style="position: sticky; right: 0; background: var(--bg2); text-align: right;">ZAČNI SVETOVANJE</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(queue, _, index) in queues" :key="queue.sip_id" :class="{ diffBg: index % 2 == 0 }">
            <td>{{ queue.order }}</td>
            <td>{{ queue.data.caller_id }}</td>
            <td>{{ queue.data.new_ContactID ?? queue.data.new_ContactId }}</td>
            <td style="font-weight: 600;">{{ queue.data.contactFullName }}</td>
            <td>{{ queue.data.accountName }}</td>
            <td>{{ queue.wait }}</td>
            <td>{{ queue.data?.numberOfConsultations ?? '-' }}</td>
            <td>{{ queue.data?.consultationTime ?? '-' }}</td>
            <td style="position: sticky; right: 0; background: none; text-align: right; padding-right: 0.5rem !important;">
              <v-btn  @click="toggleStartNewCall(queue.sip_id)" color="primary" style="padding: 0; min-width: 42px; min-height: 42px;">
                <unicon style="width: 20px" name="phone-volume" fill="white"></unicon>
              </v-btn>
            </td>
          </tr>
        </tbody>
        <Spinner :text="'Nalaganje trenutnih klicev'" v-if="isLoading.liveQueue" />
      </v-table>
    </v-card>
    <br />
    <v-card variant="tonal" width="100%" class="text-left pa-4">
      <v-row style="align-items: center;">
        <div
          style="
            flex: 1;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
          "
        >
          <v-text-field
            v-model="search"
            prepend-icon="mdi-magnify"
            variant="outlined"
            density="compact"
            label="Išči po kontaktu ali naročniku"
            hide-details="true"
            clearable
          />
        </div>
        <div
          style="
            display: flex;
            align-items: center;
            gap: 0.5rem;
            margin-left: 1rem
          "
        >
          <v-select
            :items="[5, 10, 20, 50]"
            v-model="per_page"
            variant="solo"
            density="compact"
            hide-details="true"
          />
          <span>zapisov</span>
        </div>
        <v-btn  @click="addNewCall" color="primary" style="padding: 0; min-width: 40px; min-height: 40px; margin-left: 1rem">
          <unicon style="width: 20px" name="plus" fill="white"></unicon>
        </v-btn>
      </v-row>
      <br />
      <v-table style="position: relative;">
        <thead>
          <tr>
            <th class="text-subtitle-2">RFR ŠT.</th>
            <th class="text-subtitle-2">TELEFONSKA ŠTEVILKA</th>
            <th class="text-subtitle-2">OSEBA</th>
            <th style="min-width: 200px" class="text-subtitle-2">NAROČNIK</th>
            <th class="text-subtitle-2">DATUM KLICA</th>
            <th class="text-subtitle-2">TRAJANJE</th>
            <th style="min-width: 200px" class="text-subtitle-2">VPRAŠANJE</th>
            <th style="min-width: 200px" class="text-subtitle-2">KLASIFIKACIJE</th>
            <th class="text-subtitle-2">ZELO ZANIMIVO</th>
          </tr>
        </thead>
        <tbody v-if="!isLoading.finishedCalls">
          <tr v-for="(queue, index) in finishedQueues.data" :key="queue.id" :class="{ diffBg: index % 2 == 0 }">
            <th>{{ queue.ad_Contactid_PhoneCall?.ad_rfrnumber ?? '' }}</th>
            <th>{{ queue.phonenumber }}</th>
            <th class="clickable-queue" @click="toggleHistoryDialog('contact', queue.ad_Contactid_PhoneCall?.contactid)" style="color:rgb(var(--v-theme-info))">{{ queue.ad_Contactid_PhoneCall?.fullname ?? '' }}</th>
            <th class="clickable-queue" @click="toggleHistoryDialog('account', queue.ad_Accountid_PhoneCall?.accountid)" style="color:rgb(var(--v-theme-info)); min-width: 200px">{{ queue.ad_Accountid_PhoneCall?.name ?? '' }}</th>
            <th>{{ formatDateTime(queue.actualstart) }}</th>
            <th>{{ calculateHoursMinutes(queue.actualdurationminutes) }}</th>
            <th class="clickable-queue" @click="toggleCallCard(queue)" style="color:rgb(var(--v-theme-info)); min-width: 200px;">{{ queue.subject }}</th>
            <th style="min-width: 200px;">{{ getClassification(queue.description ?? '') }}</th>
            <th>
              <v-icon v-if="queue.ad_interestingcontent" @click="changeInterestingContent(queue)">mdi-star</v-icon>
              <v-icon v-else @click="changeInterestingContent(queue)">mdi-star-outline</v-icon>
            </th>
          </tr>
        </tbody>
        <Spinner v-if="isLoading.finishedCalls" :text="'Nalaganje končanih klicev'" />
      </v-table>
      <br />
      <v-row style="display: flex; flex-flow: row nowrap; align-items: center; justify-content: center; gap: 1.5rem">
          <v-icon v-if="this.finishedQueues.previous && !isLoading.finishedCalls" color="var(--accent)" size="large" @click="this.page = 1" style="cursor: pointer;">mdi-page-first</v-icon>
          <v-icon v-else size="large" style="opacity: 0.5">mdi-page-first</v-icon>

          <span v-if="this.finishedQueues.previous && !isLoading.finishedCalls" style="cursor: pointer;">
            <v-icon color="var(--accent)" size="large"  @click="this.page -= 1">mdi-chevron-left</v-icon>
          </span>
          <span v-else>
            <v-icon size="large" style="opacity: 0.5">mdi-chevron-left</v-icon>
          </span>
          <span>{{ this.finishedQueues.page }} / {{ this.finishedQueues.num_pages }}</span>
          <span v-if="this.finishedQueues.next && !isLoading.finishedCalls" style="cursor: pointer;">
            <v-icon color="var(--accent)" size="large" @click="this.page += 1">mdi-chevron-right</v-icon>
          </span>
          <span v-else >
            <v-icon size="large" style="opacity: 0.5">mdi-chevron-right</v-icon>
          </span>
          <v-icon v-if="this.finishedQueues.next && !isLoading.finishedCalls" size="large" style="cursor: pointer;" color="var(--accent)" @click="this.page = this.finishedQueues.num_pages">mdi-page-last</v-icon>
          <v-icon v-else size="large" style="opacity: 0.5">mdi-page-last</v-icon>
      </v-row>
    </v-card>
  </v-container>

  <!-- New phone call dialog -->
  <v-dialog
    v-model="startNewCall.isActive"
    fullscreen
    :scrim="false"
    transition="dialog-bottom-transition"
  >
    <v-card>
      <Spinner :text="'Nalaganje podatkov klicatelja'" v-if="isLoading.newCall" />
      <v-toolbar color="var(--accent)" style="color: white;" light flat>
        <v-btn icon dark @click="startNewCall.isActive = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Nov klic<span id="new-call-timer">{{this.timeElapsed}}</span></v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn variant="outlined" style="background-color: var(--accent);" @click="saveCallDataAndClear"><v-icon style="width: 18px; margin-right: 4px">mdi-plus</v-icon> Shrani in začni novo vprašanje</v-btn>
        <v-btn variant="outlined" style="background-color: var(--accent);" @click="saveCallData" class="ml-1"><unicon style="width: 18px; margin-right: 4px" name="save" fill="white"></unicon> Shrani</v-btn>
      </v-toolbar>
      <v-row v-if="saveError">
        <v-col>
          <v-alert type="error" color="var(--error)" style="color: white; border-radius: 0.5rem;" dismissible @input="saveError = false">
            {{ saveError }}
          </v-alert>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-table density="compact">
            <thead>
              <tr>
                <th style="width: min-content;">Prioriteta</th>
                <th>Do</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <v-select
                    :items="['Low', 'Normal', 'High']"
                    v-model="priority"
                    density="compact"
                    hide-details="true"
                    style="max-width:max-content;"
                  ></v-select>
                </td>
                <td>
                  <v-text-field
                    density="compact"
                    type="date"
                    v-model="due_date"
                    hide-details="true"
                  ></v-text-field>
                </td>
                <td>Open</td>
              </tr>
            </tbody>
          </v-table>
          <v-divider></v-divider>
          <v-table density="compact">
            <thead>
              <tr>
                <th style="width: min-content;">Telefonska številka</th>
                <th>Kontakt</th>
                <th style="width: min-content;">Naročniška številka</th>
                <th>Naročnik</th>
                <th>Produkt</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style="width: min-content;">
                  <v-text-field density="compact" v-model="phoneNum" readonly></v-text-field>
                </td>
                <td>
                  <v-text-field
                    density="compact"
                    v-model="contactZrfr"
                    readonly
                  ></v-text-field>
                </td>
                <td style="width: min-content;">
                  <v-text-field
                    density="compact"
                    v-model="accNumZrfr"
                    readonly
                  ></v-text-field>
                </td>
                <td>
                  <v-text-field
                    density="compact"
                    v-model="accZrfr"
                    readonly
                  >
                  </v-text-field>
                </td>
                <td>
                  <v-text-field
                    density="compact"
                    v-model="subLookupZrfr"
                    readonly
                  ></v-text-field>
                </td>
              </tr>
            </tbody>
          </v-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="5">
          <v-form class="q-a-form">
            <v-textarea
              variant="outlined"
              name="question"
              label="Vprašanje/Tema*"
              v-model="q"
              style="max-width: 50rem"
              hide-details="true"
            >
            </v-textarea>
            <v-textarea
              variant="outlined"
              name="ans"
              label="Odgovor*"
              v-model="a"
              style="max-width: 50rem"
              hide-details="true"
            >
            </v-textarea>
          </v-form>
          <v-checkbox
            v-model="interesting_content"
            label="Zelo zanimivo"
            hide-details="true"
          ></v-checkbox>
          <v-row>
            <v-col>
              <h2>Zgodovina svetovanj trenutnega klicatelja</h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div
                style="
                  display: flex;
                  align-items: center;
                  gap: 0.5rem;
                "
              >
                <v-select
                  :items="[5, 10, 20, 50]"
                  v-model="dialogQueue_per_page"
                  variant="solo"
                  density="compact"
                  hide-details="true"
                />
                <span>zapisov</span>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-table density="compact">
                <thead>
                  <tr>
                    <th>Čas klica</th>
                    <th>Svetovalec</th>
                    <th>Vprašanje/Tema</th>
                    <th>Odgovor</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(queue, index) in dialogQueue.data" :key="queue.id" :class="{ diffBg: index % 2 == 0 }">
                    <td>{{ formatDateTime(queue.actualstart) }}</td>
                    <td>{{ queue.ad_tousertxt }}</td>
                    <td class="clickable-queue" @click="toggleCallCard(queue)" style="color:rgb(var(--v-theme-info))">{{ queue.subject }}</td>
                    <td>{{ queue.description }}</td>
                  </tr>
                </tbody>
              </v-table>
            </v-col>
          </v-row>
          <v-row style="display: flex; flex-flow: row nowrap; align-items: center; justify-content: center; gap: 1.5rem">
            <v-icon v-if="this.dialogQueue.previous" color="var(--accent)" size="large" @click="this.dialogQueue_page = 1" style="cursor: pointer;">mdi-page-first</v-icon>
            <v-icon v-else size="large" style="opacity: 0.5">mdi-page-first</v-icon>

            <span v-if="this.dialogQueue.previous" style="cursor: pointer;">
              <v-icon color="var(--accent)" size="large"  @click="this.dialogQueue_page -= 1">mdi-chevron-left</v-icon>
            </span>
            <span v-else>
              <v-icon size="large" style="opacity: 0.5">mdi-chevron-left</v-icon>
            </span>
            <span>{{ this.dialogQueue_page }} / {{ this.dialogQueue.num_pages }}</span>
            <span v-if="this.dialogQueue.next" style="cursor: pointer;">
              <v-icon color="var(--accent)" size="large" @click="this.dialogQueue_page += 1">mdi-chevron-right</v-icon>
            </span>
            <span v-else >
              <v-icon size="large" style="opacity: 0.5">mdi-chevron-right</v-icon>
            </span>
            <v-icon v-if="this.dialogQueue.next" size="large" style="cursor: pointer;" color="var(--accent)" @click="this.dialogQueue_page = this.dialogQueue.num_pages">mdi-page-last</v-icon>
            <v-icon v-else size="large" style="opacity: 0.5">mdi-page-last</v-icon>
          </v-row>
        </v-col>
        <v-col>
          <ClassificationForm @classification="selectClassification"/>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
  <!-- History dialog -->
  <v-dialog
    v-model="historyDialog"
    transition="dialog-top-transition"
    max-width="96%"
    >
    <v-card>
      <v-toolbar
        color="var(--accent)"
        style="color:white"
        light
        flat
        title="Zgodovina svetovanj"
      >
        <v-btn icon dark @click="historyDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card class="text-left pa-4" style="overflow: auto; position: relative">
          <Spinner v-if="isLoading.historyDialog" :text="'Nalaganje zgodovine svetovanj'" />
          <v-row>
            <v-col cols="2">
              <div
                style="
                  display: flex;
                  align-items: center;
                  height: 50px;
                  gap: 0.5rem;
                "
              >
                <v-select
                  :items="[5, 10, 20, 50]"
                  v-model="history_per_page"
                  variant="solo"
                  density="compact"
                  hide-details="true"
                  style="min-width: max-content !important;"
                />
                <span>zapisov</span>
              </div>
            </v-col>
          </v-row>
          <v-row v-if="!isLoading.historyDialog">
            <v-col>
              <v-table>
                <thead>
                  <tr>
                    <th class="text-subtitle-2">RFR ŠT.</th>
                    <th class="text-subtitle-2">TELEFONSKA ŠTEVILKA</th>
                    <th class="text-subtitle-2">IME IN PRIIMEK</th>
                    <th class="text-subtitle-2">NAROČNIK</th>
                    <th class="text-subtitle-2">SVETOVALEC</th>
                    <th class="text-subtitle-2">DATUM KLICA</th>
                    <th class="text-subtitle-2">TRAJANJE</th>
                    <th class="text-subtitle-2">TEMA</th>
                    <th class="text-subtitle-2">KLASIFIKACIJE</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(history, index) in historyQueue.data" :key="history.id" :class="{ diffBg: index % 2 == 0 }">
                    <td>{{ history.ad_Contactid_PhoneCall?.ad_rfrnumber ?? '' }}</td>
                    <td>{{ history.phonenumber ?? '' }}</td>
                    <td>{{ history.ad_Contactid_PhoneCall?.fullname ?? '' }}</td>
                    <td>{{ history.ad_Accountid_PhoneCall?.name ?? '' }}</td>
                    <td>{{ history.ad_tousertxt ?? '' }}</td>
                    <td>{{ formatDateTime(history.actualstart) }}</td>
                    <td>{{ calculateHoursMinutes(history.actualdurationminutes) }}</td>
                    <td class="clickable-queue" @click="toggleCallCard(history)" style="color:rgb(var(--v-theme-info))">{{ history.subject ?? '' }}</td>
                    <td style="min-width: 200px;">{{ getClassification(history.description ?? '') }}</td>
                  </tr>
                </tbody>
              </v-table>
            </v-col>
          </v-row>
          <v-row style="display: flex; flex-flow: row nowrap; align-items: center; justify-content: center; gap: 1.5rem">
            <v-icon v-if="this.historyQueue.previous" color="var(--accent)" size="large" @click="this.history_page = 1" style="cursor: pointer;">mdi-page-first</v-icon>
            <v-icon v-else size="large" style="opacity: 0.5">mdi-page-first</v-icon>

            <span v-if="this.historyQueue.previous" style="cursor: pointer;">
              <v-icon color="var(--accent)" size="large"  @click="this.history_page -= 1">mdi-chevron-left</v-icon>
            </span>
            <span v-else>
              <v-icon size="large" style="opacity: 0.5">mdi-chevron-left</v-icon>
            </span>
            <span>{{ this.history_page }} / {{ this.historyQueue.num_pages }}</span>
            <span v-if="this.historyQueue.next" style="cursor: pointer;">
              <v-icon color="var(--accent)" size="large" @click="this.history_page += 1">mdi-chevron-right</v-icon>
            </span>
            <span v-else >
              <v-icon size="large" style="opacity: 0.5">mdi-chevron-right</v-icon>
            </span>
            <v-icon v-if="this.historyQueue.next" size="large" style="cursor: pointer;" color="var(--accent)" @click="this.history_page = this.historyQueue.num_pages">mdi-page-last</v-icon>
            <v-icon v-else size="large" style="opacity: 0.5">mdi-page-last</v-icon>
          </v-row>
      </v-card>
    </v-card>
  </v-dialog>
  <!-- History card dialog -->
  <v-dialog
    v-model="callCardDialog"
    transition="dialog-top-transition"
    max-width="96%"
  >
    <v-card>
      <v-toolbar
        color="var(--accent)"
        style="color: white;"
        light
        flat
        title="Zgodovina klica"
      >
        <v-btn icon dark @click="callCardDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container grid-list-lg>
        <v-row>
          <v-col>
            <v-table density="compact">
              <thead>
                <tr>
                  <th>Prioriteta</th>
                  <th>Do</th>
                  <th>Status</th>
                  <th>Svetovalec</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <v-text-field
                      density="compact"
                      readonly>
                      {{ getPriority(callCardData.prioritycode) }}
                    </v-text-field>
                  </td>
                  <td>
                  <v-text-field
                      density="compact"
                      readonly>
                      {{ formatDateTime(callCardData.scheduledstart) }}
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      density="compact"
                      value="Closed"
                      readonly></v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      density="compact"
                      v-model="callCardData.ad_tousertxt"
                      readonly></v-text-field>
                  </td>
                </tr>
              </tbody>
            </v-table>
            <v-divider></v-divider>
            <v-table>
              <thead>
                <tr>
                  <th style="width: min-content;">Telefonska številka</th>
                  <th>RFR ŠT.</th>
                  <th>Oseba</th>
                  <th style="width: min-content;">Naročniška številka</th>
                  <th>Naročnik</th>
                  <!-- <th>Produkti</th> -->
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style="width: min-content;">
                    <v-text-field
                      density="compact"
                      v-model="callCardData.phonenumber"
                      readonly></v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      density="compact"
                      v-model="callCardData.ad_Contactid_PhoneCall.ad_rfrnumber"
                      readonly
                      ></v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      density="compact"
                      v-model="callCardData.ad_Contactid_PhoneCall.fullname"
                      readonly></v-text-field>
                  </td>
                  <td style="width: min-content;">
                    <v-text-field
                      density="compact"
                      v-model="callCardData.ad_Accountid_PhoneCall.accountnumber"
                      readonly
                      ></v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      density="compact"
                      v-model="callCardData.ad_Accountid_PhoneCall.name"
                      readonly
                      ></v-text-field>
                  </td>
                  <!-- <td>
                    <v-select
                    :items="callCardData.subscriptions"
                    v-model="callCardData.subscription"
                    item-title="ad_name"
                    item-value="ad_subscriptionid"
                    density="compact"
                    hide-details="true"
                  ></v-select>
                  </td> -->
                </tr>
              </tbody>
            </v-table>
            <v-divider></v-divider>
            <v-table>
              <thead>
                <tr>
                  <th>Čas klica</th>
                  <th>Čas zaključka</th>
                  <th>Trajanje</th>
                  <th>Klasifikacija</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <v-text-field
                      density="compact"
                      readonly>
                      {{ formatDateTime(callCardData.actualstart) }}
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      density="compact"
                      readonly>
                      {{ formatDateTime(callCardData.actualend) }}
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      density="compact"
                      readonly>
                      {{ calculateHoursMinutes(callCardData.actualdurationminutes) }}
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      density="compact"
                      readonly>
                      {{ getClassification(callCardData.description ?? '') }}
                    </v-text-field>
                  </td>
                </tr>
              </tbody>
            </v-table>
            <v-divider></v-divider>
            <v-row>
              <v-col>
                <v-textarea
                  dense
                  readonly
                  v-model="callCardData.subject"
                  label="Vprašanje/Tema"
                ></v-textarea>
              </v-col>
              <v-col>
                <v-textarea
                  dense
                  readonly
                  v-model="callCardData.description"
                  label="Opis"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>

  <!-- Add new call dialog -->
  <v-dialog
    v-model="addNewCallDialog"
    fullscreen
    :scrim="false"
    transition="dialog-bottom-transition"
  >
    <v-card>
      <!-- <Spinner :text="'Nalaganje podatkov klicatelja'" v-if="isLoading.addNewCall" /> -->
      <v-toolbar color="var(--accent)" style="color: white;" light flat>
        <v-btn icon dark @click="addNewCallDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Ročni vnos klica</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn variant="outlined" style="background-color: var(--accent);" @click="addNewCallPOST" class="ml-1"><unicon style="width: 18px; margin-right: 4px" name="save" fill="white"></unicon> Dodaj klic</v-btn>
      </v-toolbar>
      <v-row v-if="addError">
        <v-col>
          <v-alert type="error" color="var(--error)" style="color: white; border-radius: 0.5rem;" dismissible @input="addError = false">
            {{ addError }}
          </v-alert>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-table density="compact">
            <thead>
              <tr>
                <th style="width: min-content;">Prioriteta</th>
                <th>Do</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <v-select
                    :items="['Low', 'Normal', 'High']"
                    v-model="addNewCallData.priority"
                    density="compact"
                    hide-details="true"
                  ></v-select>
                </td>
                <td>
                  <v-text-field
                    density="compact"
                    type="date"
                    v-model="addNewCallData.due_date"
                    hide-details="true"
                  ></v-text-field>
                </td>
                <td>
                  <v-select
                    :items="['Open', 'Closed']"
                    v-model="addNewCallData.status"
                    density="compact"
                    hide-details="true"
                  ></v-select>
                </td>
              </tr>
            </tbody>
          </v-table>
          <br>
          <v-table density="compact">
            <thead>
              <tr>
                <th style="width: min-content;">Telefonska številka</th>
                <th>Kontakt | Ime Priimek</th>
                <th>Kontakt | Št. ZRFR</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style="width: min-content; position: relative">
                  <v-text-field density="compact" hide-details="true" v-model="addNewCallData.phonenumber"></v-text-field>
                  <Spinner v-if="addNewCallData.isLoading.phonenumber" />
                </td>
                <td style="position: relative">
                  <v-text-field
                    density="compact"
                    v-model="addNewCallData.contactName"
                    hide-details="true"
                    @input="addNewCallInput('contactName')"

                  >
                    <v-btn  @click="addNewCallSearchButton('contactName')" color="primary" style="padding: 0; min-width: 36px; max-width: 36px; min-height: 32px; margin-right: 1rem;">
                      <unicon style="width: 18px" name="search" fill="white"></unicon>
                    </v-btn>
                  </v-text-field>
                  <v-select
                    :items="addNewCallData.foundContactItems"
                    v-model="addNewCallData.foundContact"
                    v-if="addNewCallData.foundContactItems.length"
                    density="compact"
                    hide-details="true"
                    style="opacity: 0.5"
                    item-title="fullname"
                    item-value="ad_rfrnumber"
                    @update:modelValue="handleFoundChange('contact')"
                  ></v-select>
                  <Spinner v-if="addNewCallData.isLoading.contactName" />
                </td>
                <td style="width: min-content; position: relative">
                  <v-text-field
                    density="compact"
                    v-model="addNewCallData.contactZrfr"
                    hide-details="true"
                    @input="addNewCallInput('contactZrfr')"
                  >
                    <v-btn  @click="addNewCallSearchButton('contactZrfr')" color="primary" style="padding: 0; min-width: 36px; max-width: 36px; min-height: 32px; margin-right: 1rem;">
                      <unicon style="width: 18px" name="search" fill="white"></unicon>
                    </v-btn>
                  </v-text-field>
                  <Spinner v-if="addNewCallData.isLoading.contactZrfr" />
                </td>
              </tr>
            </tbody>
            <br>
            <thead>
              <tr>
                <th style="width: min-content;">Naročnik | Št. ZRFR</th>
                <th>Naročnik | Ime</th>
                <th>Produkt</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style="width: min-content; position: relative">
                  <v-text-field
                    density="compact"
                    v-model="addNewCallData.accountNumber"
                    hide-details="true"
                    @input="addNewCallInput('accountNumber')"
                  >
                    <v-btn  @click="addNewCallSearchButton('accountNumber')" color="primary" style="padding: 0; min-width: 36px; max-width: 36px; min-height: 32px; margin-right: 1rem;">
                      <unicon style="width: 18px" name="search" fill="white"></unicon>
                    </v-btn>
                  </v-text-field>
                  <Spinner v-if="addNewCallData.isLoading.accountNumber" />
                </td>
                <td style="position: relative;">
                  <v-text-field
                    density="compact"
                    v-model="addNewCallData.accountName"
                    hide-details="true"
                    @input="addNewCallInput('accountName')"
                  >
                  <v-btn  @click="addNewCallSearchButton('accountName')" color="primary" style="padding: 0; min-width: 36px; max-width: 36px; min-height: 32px; margin-right: 1rem;">
                    <unicon style="width: 18px" name="search" fill="white"></unicon>
                  </v-btn>
                  </v-text-field>
                  <v-select
                    :items="addNewCallData.foundAccountItems"
                    v-model="addNewCallData.foundAccount"
                    v-if="addNewCallData.foundAccountItems.length"
                    density="compact"
                    hide-details="true"
                    item-title="name"
                    item-value="accountnumber"
                    @update:modelValue="handleFoundChange('account')"
                    style="opacity: 0.5"
                  ></v-select>
                  <Spinner v-if="addNewCallData.isLoading.accountName" />
                </td>
                <td style="position: relative">
                  <v-select
                    :items="addNewCallData.products"
                    v-model="addNewCallData.product"
                    item-title="ad_name"
                    item-value="ad_subscriptionid"
                    density="compact"
                    hide-details="true"
                  ></v-select>
                  <Spinner v-if="addNewCallData.isLoading.product" />
                </td>
              </tr>
            </tbody>
          </v-table>
        </v-col>
      </v-row>
      <v-row>
        <v-form class="q-a-form">
          <v-textarea
            variant="outlined"
            name="question"
            label="Vprašanje/Tema*"
            v-model="addNewCallData.q"
            style="max-width: 50rem"
            hide-details="true"
          >
          </v-textarea>
          <v-textarea
            variant="outlined"
            name="ans"
            label="Odgovor*"
            v-model="addNewCallData.a"
            style="max-width: 50rem"
            hide-details="true"
          >
          </v-textarea>
          <v-checkbox
          style="max-width: max-content; padding: 0 12px; max-height: max-content"
          v-model="addNewCallData.interestingContent"
          label="Zelo zanimivo"
          hide-details="true"
        ></v-checkbox>
        </v-form>
      </v-row>
      <v-row>
        <v-col>
          <ClassificationForm @classification="selectAddClassification"/>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import { getLiveQueue } from '@/services/api/queue'
import { refreshToken } from '@/services/api/auth'
import {
  getNumberOfPhonecalls,
  getCallUserDetails,
  getRedicrectCall,
  createCall,
  getUserPreviousCalls,
  getAllCalls,
  toggleInterestingCall,
  searchQuery
} from '@/services/api/crm'
import ClassificationForm from '@/components/ClassificationForm.vue'
import Spinner from '@/components/Spinner.vue'

export default {
  name: 'ConsultingView',
  data () {
    return {
      name: 'ConsultingView',
      callType: 'contact',
      per_page: 10,
      page: 1,
      consults_today: '-',
      search: '',
      checkbox: '',
      accZrfr: '--',
      accNumZrfr: '--',
      subLookupZrfr: '--',
      contactZrfr: '--',
      contactNumZrfr: '--',
      phoneNum: '--',
      q: null,
      a: null,
      interesting_content: false,
      duration: 0,
      queues: {},
      due_date: null,
      classification: null,
      priority: 'Normal',
      actualstart: null,
      actualend: null,
      selectedSipId: null,
      selectedSipData: null,
      finishedQueues: {},
      hasFinishedQueue: true,
      dialogQueue: [],
      dialogQueue_per_page: 10,
      dialogQueue_page: 1,
      startNewCall: {
        isActive: false,
        timerInterval: null
      },
      timeElapsed: '00:00',
      saveError: null,
      addError: null,
      historyDialog: false,
      historyQueue: {},
      historyOpenID: null,
      historyOpenType: 'contact',
      history_per_page: 10,
      history_page: 1,
      callCardDialog: false,
      callCardData: {},
      addNewCallDialog: false,
      addNewCallData: {
        priority: 'Normal',
        due_date: null,
        status: 'Open',
        q: null,
        a: null,
        classification: null,
        phonenumber: null,
        accountName: null,
        accountNumber: null,
        foundAccount: null,
        foundAccountItems: [],
        contactName: null,
        contactZrfr: null,
        foundContact: null,
        foundContactItems: [],
        product: null,
        products: [],
        interestingContent: false,
        isLoading: {
          accountName: false,
          accountNumber: false,
          contactName: false,
          contactZrfr: false,
          phonenumber: false,
          product: false
        }
      },
      isLoading: {
        finishedCalls: true,
        liveQueue: true,
        historyDialog: true,
        newCall: true,
        addNewCall: true,
        dialog: true
      }
    }
  },
  methods: {
    hmsToSecondsOnly (str) {
      const p = str.split(':')
      let s = 0; let m = 1

      while (p.length > 0) {
        s += m * parseInt(p.pop(), 10)
        m *= 60
      }

      return s
    },
    toggleStartNewCall (sipId) {
      // console.log('toggleStartNewCall sipId')
      // console.log(sipId)
      // console.log('toggleStartNewCall queues[sipId]')
      // console.log(this.queues[sipId])

      this.startNewCall.isActive = true
      this.timeElapsed = '00:00'
      if (this.startNewCall.timerInterval != null) {
        clearInterval(this.startNewCall.timerInterval)
        this.startNewCall.timerInterval = null
      }

      this.startNewCall.timerInterval = setInterval(() => {
        const s = this.hmsToSecondsOnly(this.timeElapsed) + 1
        this.timeElapsed = new Date(s * 1000).toISOString().substring(14, 19)
      }, 1000)

      this.isLoading.newCall = false

      this.accZrfr = this.queues[sipId].data.accountName
      this.accNumZrfr = this.queues[sipId].data.accountNumber?.toString()
      this.subLookupZrfr = this.queues[sipId].data.ProductName
      this.contactZrfr = this.queues[sipId].data.contactFullName
      this.phoneNum = this.queues[sipId].caller_id
      this.contactNumZrfr = this.queues[sipId].data.new_ContactId.toString()
      this.actualstart = new Date()
      this.actualstart = this.actualstart.toISOString()
      this.interesting_content = false

      this.selectedSipId = sipId
      this.selectedSipData = this.queues[sipId].data

      // Get redirect call data
      getRedicrectCall(this.queues[sipId].sip_id)
        .then((res) => {
          // console.log('SUCCESS - getRedicrectCall')
          // console.log(res)
        })
        .catch((err) => {
          console.log('ERROR - getRedicrectCall')
          console.log(err)
        })
      this.dialogQueue_page = 1
      this.isLoading.dialog = true
      // Get previous calls
      getUserPreviousCalls(this.queues[sipId].data.ContactId, this.dialogQueue_page, this.dialogQueue_per_page, 'contact')
        .then((res) => {
          // console.log('SUCCESS - getUserPreviousCalls')
          // console.log(res?.previous_calls ?? '')
          this.dialogQueue = res?.previous_calls ?? []
          this.isLoading.dialog = false
        })
        .catch((err) => {
          console.log('ERROR - getUserPreviousCalls')
          console.log(err)
          this.isLoading.dialog = true
        })
    },
    toggleHistoryDialog (type, id) {
      this.historyOpenID = id
      this.historyOpenType = type
      this.history_page = 1
      this.isLoading.historyDialog = true
      this.historyDialog = !this.historyDialog
      getUserPreviousCalls(id, this.history_page, this.history_per_page, type).then(res => {
        // console.log('SUCCESS - getUserPreviousCalls')
        // console.log(res?.previous_calls ?? '')
        this.isLoading.historyDialog = false
        this.historyQueue = res?.previous_calls ?? {}
      }).catch((err) => {
        console.log('ERROR - getUserPreviousCalls')
        console.log(err)
        this.isLoading.historyDialog = true
      })
    },
    toggleCallCard (data) {
      // console.log('TOGGLE')
      // console.log(data)
      this.callCardDialog = !this.callCardDialog
      this.callCardData = data
    },
    addNewCall () {
      this.addNewCallData = {
        priority: 'Normal',
        due_date: null,
        status: 'Open',
        q: null,
        a: null,
        classification: null,
        phonenumber: null,
        accountName: null,
        accountNumber: null,
        foundAccount: null,
        foundAccountItems: [],
        contactName: null,
        contactZrfr: null,
        foundContact: null,
        foundContactItems: [],
        product: null,
        products: [],
        interestingContent: false,
        isLoading: {
          accountName: false,
          accountNumber: false,
          contactName: false,
          contactZrfr: false,
          phonenumber: false,
          product: false
        }
      }
      this.addNewCallDialog = true
    },
    handleFoundChange (type) {
      if (type === 'contact') {
        const foundContact = this.addNewCallData.foundContactItems.find(contact => contact.ad_rfrnumber === this.addNewCallData.foundContact)
        if (foundContact) {
          this.addNewCallData.phonenumber = foundContact.mobilephone ?? null
          this.addNewCallData.contactName = foundContact.fullname ?? null
          this.addNewCallData.contactZrfr = foundContact.ad_rfrnumber ?? null
        }
      } else if (type === 'account') {
        const foundAccount = this.addNewCallData.foundAccountItems.find(account => account.accountnumber === this.addNewCallData.foundAccount)
        if (foundAccount) {
          this.addNewCallData.accountName = foundAccount.name ?? null
          this.addNewCallData.accountNumber = foundAccount.accountnumber ?? null
          this.addNewCallData.products = foundAccount.ad_account_ad_subscription_Accountid ?? []
          if (this.addNewCallData.products.length > 0) {
            this.addNewCallData.product = this.addNewCallData.products[0]
          } else this.addNewCallData.product = null
        }
      }
    },
    addNewCallSearchButton (type = null) {
      if (type === 'contactName') {
        const query = {
          entity: 'contacts',
          select: 'fullname,ad_rfrnumber,mobilephone',
          filter: `contains(fullname,'${this.addNewCallData.contactName}')`
        }
        this.addNewCallData.isLoading.contactZrfr = true
        this.addNewCallData.isLoading.contactName = true
        this.addNewCallData.isLoading.phonenumber = true
        searchQuery(query).then((res) => {
          // console.log(res?.ret)
          this.generateSelect(res?.ret, 'contact')
          this.addNewCallData.contactName = res?.ret?.[0]?.fullname
          this.addNewCallData.contactZrfr = res?.ret?.[0]?.ad_rfrnumber
          this.addNewCallData.contactId = res?.ret?.[0]?.contactid
          this.addNewCallData.phonenumber = res?.ret?.[0]?.mobilephone
          this.addNewCallData.isLoading.contactZrfr = false
          this.addNewCallData.isLoading.contactName = false
          this.addNewCallData.isLoading.phonenumber = false
        }).catch((err) => {
          console.log(err)
          this.addNewCallData.isLoading.contactZrfr = false
          this.addNewCallData.isLoading.contactName = false
          this.addNewCallData.isLoading.phonenumber = false
          this.addNewCallData.contactName = null
          this.addNewCallData.contactZrfr = null
          this.addNewCallData.contactId = null
          this.addNewCallData.phonenumber = null
        })
      } else if (type === 'accountName') {
        const query = {
          entity: 'accounts',
          select: 'name,accountnumber',
          filter: `contains(name,'${this.addNewCallData.accountName}')`,
          expand: 'ad_account_ad_subscription_Accountid($select=ad_name,ad_subscriptionid)'
        }
        this.addNewCallData.isLoading.product = true
        this.addNewCallData.isLoading.accountNumber = true
        this.addNewCallData.isLoading.accountName = true
        searchQuery(query).then((res) => {
          // console.log(res?.ret)
          this.generateSelect(res?.ret, 'account')
          this.addNewCallData.accountName = res?.ret?.[0]?.name
          this.addNewCallData.accountNumber = res?.ret?.[0]?.accountnumber
          this.addNewCallData.accountId = res?.ret?.[0]?.accountid
          const subscriptionArray = res?.ret?.[0]?.ad_account_ad_subscription_Accountid
          this.addNewCallData.products = subscriptionArray ?? []
          if (subscriptionArray && subscriptionArray.length > 0) {
            this.addNewCallData.product = subscriptionArray[0]
          } else this.addNewCallData.product = null
          this.addNewCallData.isLoading.accountNumber = false
          this.addNewCallData.isLoading.accountName = false
          this.addNewCallData.isLoading.product = false
        }).catch((err) => {
          console.log(err)
          this.addNewCallData.isLoading.accountNumber = false
          this.addNewCallData.isLoading.accountName = false
          this.addNewCallData.isLoading.product = false
          this.addNewCallData.accountName = null
          this.addNewCallData.accountNumber = null
          this.addNewCallData.accountId = null
        })
      } else if (type === 'contactZrfr') {
        const query = {
          entity: 'contacts',
          select: 'fullname,ad_rfrnumber,mobilephone',
          filter: `(ad_rfrnumber eq '${this.addNewCallData.contactZrfr}')`
        }
        this.addNewCallData.isLoading.contactZrfr = true
        this.addNewCallData.isLoading.contactName = true
        this.addNewCallData.isLoading.phonenumber = true
        searchQuery(query).then((res) => {
          // console.log(res?.ret)
          this.generateSelect(res?.ret, 'contact')
          this.addNewCallData.contactName = res?.ret?.[0]?.fullname
          this.addNewCallData.contactId = res?.ret?.[0]?.contactid
          this.addNewCallData.phonenumber = res?.ret?.[0]?.mobilephone
          this.addNewCallData.isLoading.contactZrfr = false
          this.addNewCallData.isLoading.contactName = false
          this.addNewCallData.isLoading.phonenumber = false
        }).catch((err) => {
          console.log(err)
          this.addNewCallData.isLoading.contactZrfr = false
          this.addNewCallData.isLoading.contactName = false
          this.addNewCallData.isLoading.phonenumber = false
          this.addNewCallData.contactName = null
          this.addNewCallData.contactId = null
          this.addNewCallData.phonenumber = null
        })
      } else if (type === 'accountNumber') {
        let backup = this.addNewCallData.accountNumber ?? ''
        let query = null
        if (backup.length === 5) {
          backup = `${backup}00`
          query = {
            entity: 'accounts',
            select: 'name,accountnumber',
            filter: `(accountnumber eq '${this.addNewCallData.accountNumber}' or accountnumber eq '${backup}')`,
            expand: 'ad_account_ad_subscription_Accountid($select=ad_name,ad_subscriptionid)'
          }
        } else {
          query = {
            entity: 'accounts',
            select: 'name,accountnumber',
            filter: `(accountnumber eq '${this.addNewCallData.accountNumber}')`,
            expand: 'ad_account_ad_subscription_Accountid($select=ad_name,ad_subscriptionid)'
          }
        }
        this.addNewCallData.isLoading.accountNumber = true
        this.addNewCallData.isLoading.accountName = true
        this.addNewCallData.isLoading.product = true
        searchQuery(query).then((res) => {
          // console.log(res?.ret)
          this.generateSelect(res?.ret, 'account')
          this.addNewCallData.accountName = res?.ret?.[0]?.name
          this.addNewCallData.accountNumber = res?.ret?.[0]?.accountnumber
          this.addNewCallData.accountId = res?.ret?.[0]?.accountid
          const subscriptionArray = res?.ret?.[0]?.ad_account_ad_subscription_Accountid
          this.addNewCallData.products = subscriptionArray ?? []
          if (subscriptionArray && subscriptionArray.length > 0) {
            this.addNewCallData.product = subscriptionArray[0]
          } else this.addNewCallData.product = null
          this.addNewCallData.isLoading.accountNumber = false
          this.addNewCallData.isLoading.accountName = false
          this.addNewCallData.isLoading.product = false
        }).catch((err) => {
          console.log(err)
          this.addNewCallData.isLoading.accountNumber = false
          this.addNewCallData.isLoading.accountName = false
          this.addNewCallData.isLoading.product = false
          this.addNewCallData.accountName = null
          this.addNewCallData.accountNumber = null
          this.addNewCallData.accountId = null
        })
      }
    },
    generateSelect (results, type) {
      if (!results || results.length <= 1) {
        if (type === 'contact') {
          this.addNewCallData.foundContactItems = []
        } else {
          this.addNewCallData.foundAccountItems = []
        }
        return
      }
      if (type === 'contact') {
        this.addNewCallData.foundContactItems = []
        this.addNewCallData.foundContactItems = results
        this.addNewCallData.foundContact = results[0]
      } else {
        this.addNewCallData.foundAccountItems = []
        this.addNewCallData.foundAccountItems = results
        this.addNewCallData.foundAccount = results[0]
      }
    },
    addNewCallInput (type = null) {
      if (type === 'contactZrfr') {
        this.addNewCallData.isLoading.contactName = false
      } else if (type === 'accountNumber') {
        this.addNewCallData.isLoading.accountName = false
      }
    },
    addNewCallPOST () {
      if (this.addNewCallData.q === null) {
        this.addError = 'Vprašanje/Tema je obvezno polje'
        return
      } else if (this.addNewCallData.classification === null) {
        this.addError = 'Prosim izberite klasifikacijo'
        return
      } else if (this.addNewCallData.phonenumber === null || this.addNewCallData.phonenumber.length < 8) {
        this.addError = 'Vpiši veljavno telefonsko številko'
        return
      } else if (this.addNewCallData.contactZrfr === null) {
        this.addError = 'Vpiši veljavno ZRFR številko kontakta'
        return
      } else if (this.addNewCallData.accountNumber === null) {
        this.addError = 'Vpiši veljavno naročniško številko'
        return
      }
      this.addNewCallData.actualstart = new Date()
      this.addNewCallData.actualstart = this.addNewCallData.actualstart.toISOString()
      this.addNewCallData.actualend = this.addNewCallData.actualstart
      this.addNewCallData.actualdurationminutes = 0

      const prio = { Low: 0, Normal: 1, High: 2 }
      const data = {
        priority: prio[this.addNewCallData.priority],
        due: this.addNewCallData.due_date,
        subject: this.addNewCallData.q,
        description: (this.addNewCallData.a === null) ? this.addNewCallData.classification : this.addNewCallData.a + '\n' + this.addNewCallData.classification,
        contact_id: this.addNewCallData.contactId,
        contact_name: this.addNewCallData.contactName,
        contact_number: this.addNewCallData.contactZrfr,
        account_id: this.addNewCallData.accountId,
        account_name: this.addNewCallData.accountName,
        account_number: this.addNewCallData.accountNumber,
        subscription_name: this.addNewCallData.product,
        phonenumber: this.addNewCallData.phonenumber,
        direction: 'Inbound',
        actualstart: this.addNewCallData.actualstart,
        actualend: this.addNewCallData.actualend,
        actualdurationminutes: this.addNewCallData.actualdurationminutes,
        interesting_content: this.addNewCallData.interestingContent
      }

      // Close dialog
      this.addNewCallDialog = false
      this.addError = null

      this.addNewCallData.due_date = null
      this.addNewCallData.q = null
      this.addNewCallData.a = null
      this.addNewCallData.classification = null
      this.addNewCallData.priority = 'Normal'

      // console.log('Need to store new call /w data:')
      // console.log(data)

      // Create new call
      createCall(data)
        .then((res) => {
          // console.log('SUCCESS - createCall')
          // console.log(res ?? '')

          this.isLoading.finishedCalls = true
          getAllCalls(this.page, this.per_page, null)
            .then((res) => {
              // console.log('SUCCESS - getAllCalls')
              // console.log(res?.all_calls ?? '')
              this.finishedQueues = res?.all_calls ?? {}
              this.isLoading.finishedCalls = false
            })
            .catch((err) => {
              if (err?.code === 'ERR_CANCELED') return
              console.log('ERROR - getAllCalls')
              console.log(err)
              this.isLoading.finishedCalls = true
            })

          getNumberOfPhonecalls().then((res) => {
            // console.log('SUCCESS - getNumberOfPhonecalls')
            // console.log(res?.number_of_phonecalls ?? 0)
            this.consults_today = res?.number_of_phonecalls?.toString() ?? '-' // CHANGE to length once API is fixed
          }).catch((err) => {
            console.log('ERROR - getNumberOfPhonecalls')
            console.log(err ?? 'undefined error')
          })
        })
        .catch((err) => {
          console.log('ERROR - createCall')
          console.log(err)
        })
    },
    storeNewCall (srvData) {
      /**
       * @description - Function for storing data from server to local object
       * @param {Object} srvData - Object with data from server
       */

      const queueSipIds = Object.keys(this.queues)
      const srvDataSipIds = srvData.map((val) => val.sip_id)
      // console.log(this.queues)
      queueSipIds.forEach((queueSipId) => {
        // console.log(`checking ${queueSipId}`)
        if (!srvDataSipIds.includes(queueSipId)) delete this.queues[queueSipId]
      })

      // console.log('TOLE JE srvData')
      let offset = 0
      if (srvData && srvData.length > 0) {
        if (srvData[0].order > 1) {
          offset = parseInt(srvData[0].order) - 1
        }
      }
      srvData.forEach((value) => {
        // console.log(value)
        console.log(`value order = ${value.order}, offset = ${offset}`)
        value.order = parseInt(value.order) - offset
        if (!(value.sip_id in this.queues)) {
          this.queues[value.sip_id] = value
          this.queues[value.sip_id].data.accountnumber = value.data.accountNumber
          this.queues[value.sip_id].data.name = value.data.accountName
          setTimeout(() =>
            getCallUserDetails(this.callType, id)
              .then((res) => {
                // console.log('SUCCESS - getCallUserDetails')
                // console.log(res?.user_details ?? '?')
                // console.log(this.queues[value.sip_id])
                // CHANGE to OCDATA once API is fixed
                this.queues[value.sip_id].data.consultationTime = res?.user_details?.consultationTime ?? '-'
                this.queues[value.sip_id].data.consultationTimeToday = res?.user_details?.consultationTimeToday ?? '-'
                this.queues[value.sip_id].data.numberOfConsultations = res?.user_details?.numberOfConsultations ?? '-'
                this.queues[value.sip_id].data.numberOfConsultationsToday = res?.user_details?.numberOfConsultationsToday ?? '-'
              })
              .catch((err) => {
                console.log('ERROR - getCallUserDetails')
                console.log(err ?? 'undefined error')
              }), 50)
          const id = (this.callType === 'contact') ? value.data.ContactId : value.data.AccountId
        } else {
          this.queues[value.sip_id].order = value.order
          this.queues[value.sip_id].wait = value.wait
        }
      })

      this.sortQueue()
    },
    selectClassification ({ id, name }) {
      this.classification = name
    },
    selectAddClassification ({ id, name }) {
      this.addNewCallData.classification = name
    },
    sortQueue () {
      let entries = Object.entries(this.queues)
      entries = entries.sort((a, b) => {
        return a[1].order - b[1].order
      })

      const sorted = {}
      for (const [key, value] of entries) {
        sorted[key] = value
      }
      this.queues = sorted
    },
    saveCallData () {
      /**
       * @description - Function for saving data from new call
       */
      if (this.q === null) {
        this.saveError = 'Vprašanje/Tema je obvezno polje'
        return
      }
      if (this.classification === null) {
        this.saveError = 'Prosim izberite klasifikacijo'
        return
      }
      this.actualend = new Date()
      this.actualend = this.actualend.toISOString()
      // Duration in minutes
      this.duration = Math.floor((new Date(this.actualend) - new Date(this.actualstart)) / 60000)
      const prio = { Low: 0, Normal: 1, High: 2 }
      // console.log('Trying to save call data for call: ')
      // console.log(this.selectedSipData)
      const data = {
        priority: prio[this.priority],
        due: this.due_date,
        subject: this.q,
        description: (this.a === null) ? this.classification : this.a + '\n' + this.classification,
        contact_id: this.selectedSipData.ContactId,
        contact_name: this.selectedSipData.contactFullName,
        contact_number: this.selectedSipData.new_ContactId,
        account_id: this.selectedSipData.AccountId,
        account_name: this.selectedSipData.name,
        account_number: this.selectedSipData.accountnumber,
        subscription_id: this.selectedSipData.SubscriptionId || null,
        subscription_name: this.selectedSipData.ProductName,
        phonenumber: this.phoneNum,
        direction: 'Inbound',
        actualstart: this.actualstart,
        actualend: this.actualend,
        actualdurationminutes: this.duration,
        interesting_content: this.interesting_content
      }

      // Remove call from queue
      if (this.selectedSipId in this.queues) { delete this.queues[this.selectedSipId] }
      this.dialogQueue = []
      // Close dialog
      this.startNewCall.isActive = false
      this.saveError = null

      this.due_date = null
      this.q = null
      this.a = null
      this.classification = null
      this.priority = 'Normal'

      // Create new call
      createCall(data)
        .then((res) => {
          // console.log('SUCCESS - createCall')
          // console.log(res ?? '')

          this.isLoading.finishedCalls = true
          getAllCalls(this.page, this.per_page, null)
            .then((res) => {
              // console.log('SUCCESS - getAllCalls')
              // console.log(res?.all_calls ?? '')
              this.finishedQueues = res?.all_calls ?? {}
              this.isLoading.finishedCalls = false
            })
            .catch((err) => {
              if (err?.code === 'ERR_CANCELED') return
              console.log('ERROR - getAllCalls')
              console.log(err)
              this.isLoading.finishedCalls = true
            })

          getNumberOfPhonecalls().then((res) => {
            // console.log('SUCCESS - getNumberOfPhonecalls')
            // console.log(res?.number_of_phonecalls ?? 0)
            this.consults_today = res?.number_of_phonecalls?.toString() ?? '-' // CHANGE to length once API is fixed
          }).catch((err) => {
            console.log('ERROR - getNumberOfPhonecalls')
            console.log(err ?? 'undefined error')
          })
        })
        .catch((err) => {
          console.log('ERROR - createCall')
          console.log(err)
        })

      this.isLoading.liveQueue = true
      if (this.hasFinishedQueue) {
        this.hasFinishedQueue = false
        getLiveQueue().then((res) => {
          this.hasFinishedQueue = true
          console.log('SUCCESS - getLiveQueue')
          // console.log(res?.queue ?? '')
          if (res?.queue) this.storeNewCall(res.queue)
          this.isLoading.liveQueue = false
        }).catch((err) => {
          this.hasFinishedQueue = true
          console.log('ERROR - getLiveQueue')
          console.log(err)
        })
      }

      if (this.queue_interval) clearInterval(this.queue_interval)
      this.queue_interval = setInterval(() => {
        if (!this.hasFinishedQueue) return
        this.hasFinishedQueue = false
        getLiveQueue().then((res) => {
          this.hasFinishedQueue = true
          console.log('SUCCESS - getLiveQueue')
          // console.log(res?.queue ?? '')
          if (res?.queue) this.storeNewCall(res.queue)
          this.isLoading.liveQueue = false
        }).catch((err) => {
          this.hasFinishedQueue = true
          console.log('ERROR - getLiveQueue')
          console.log(err)
        })
      }, 20000)

      refreshToken().then(
        // console.log('SUCCESS - refreshToken')
      ).catch((err) => {
        console.log('ERROR - refreshToken [ConsultingView]')
        console.log(err)
      })
    },
    saveCallDataAndClear () {
      /**
       * @description - Function for saving data from new call
       */
      if (this.q === null) {
        this.saveError = 'Vprašanje/Tema je obvezno polje'
        return
      }
      if (this.classification === null) {
        this.saveError = 'Prosim izberite klasifikacijo'
        return
      }
      this.actualend = new Date()
      this.actualend = this.actualend.toISOString()
      // Duration in minutes
      this.duration = Math.floor((new Date(this.actualend) - new Date(this.actualstart)) / 60000)
      const prio = { Low: 0, Normal: 1, High: 2 }
      const data = {
        priority: prio[this.priority],
        due: this.due_date,
        subject: this.q,
        description: (this.a === null) ? this.classification : this.a + '\n' + this.classification,
        contact_id: this.selectedSipData.ContactId,
        contact_name: this.selectedSipData.contactFullName,
        contact_number: this.selectedSipData.new_ContactId,
        account_id: this.selectedSipData.AccountId,
        account_name: this.selectedSipData.name,
        account_number: this.selectedSipData.accountnumber,
        subscription_id: this.selectedSipData.SubscriptionId || null,
        subscription_name: this.selectedSipData.ProductName,
        phonenumber: this.phoneNum,
        direction: 'Inbound',
        actualstart: this.actualstart,
        actualend: this.actualend,
        actualdurationminutes: this.duration,
        interesting_content: this.interesting_content
      }

      this.saveError = null

      this.due_date = null
      this.q = null
      this.a = null
      this.classification = null
      this.priority = 'Normal'

      // Create new call
      createCall(data)
        .then((res) => {
          // console.log(res ?? 'create call res undefined')
          // this.isLoading.finishedCalls = true
          // getAllCalls(this.page, this.per_page, null)
          //   .then((res) => {
          //     console.log('SUCCESS - getAllCalls')
          //     console.log(res?.all_calls ?? '')
          //     console.log('burek1')
          //     this.finishedQueues = res?.all_calls ?? {}
          //     this.isLoading.finishedCalls = false
          //     console.log('burek2')
          //   })
          //   .catch((err) => {
          //     if (err?.code === 'ERR_CANCELED') return
          //     console.log('ERROR - getAllCalls')
          //     console.log('burek3')
          //     console.log(err)
          //     this.isLoading.finishedCalls = true
          //     console.log('burek4')
          //   })

          // getNumberOfPhonecalls().then((res) => {
          //   console.log('SUCCESS - getNumberOfPhonecalls')
          //   console.log(res?.number_of_phonecalls ?? 0)
          //   this.consults_today = res?.number_of_phonecalls?.toString() ?? '-' // CHANGE to length once API is fixed
          // }).catch((err) => {
          //   console.log('ERROR - getNumberOfPhonecalls')
          //   console.log(err ?? 'undefined error')
          // })

          // Get previous calls
          this.isLoading.dialog = true
          getUserPreviousCalls(this.queues[this.selectedSipId].data.ContactId, this.dialogQueue_page, this.dialogQueue_per_page, 'contact')
            .then((res) => {
              // console.log('SUCCESS - getUserPreviousCalls')
              // console.log(res?.previous_calls ?? '')
              this.dialogQueue = res?.previous_calls ?? []
              this.isLoading.dialog = false
            })
            .catch((err) => {
              console.log('ERROR - getUserPreviousCalls')
              console.log(err)
              this.isLoading.dialog = true
            })
        })
        .catch((err) => {
          console.log(err)
        })
    },
    changeInterestingContent (queue) {
      toggleInterestingCall(queue.activityid, queue.ad_interestingcontent ? '0' : '1')
        .then((res) => {
          queue.ad_interestingcontent = !queue.ad_interestingcontent
          // console.log('SUCCESS - changeInterestingContent')
          // console.log(res)
        })
        .catch((err) => {
          console.log('ERROR - changeInterestingContent')
          console.log(err)
        })
    },
    calculateHoursMinutes (minutes) {
      /**
       * @description - Function for calculating hours and minutes from minutes
       * @param {Number} minutes - Number of minutes
       * @returns {String} - String with hours and minutes
       */
      const hours = Math.floor(minutes / 60)
      const mins = minutes % 60
      return `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`
    },
    formatDateTime (datetime) {
      /**
       * @description - Function for formatting datetime
       * @param {String} datetime - Datetime string
       * @returns {String} - Formatted datetime string
       */
      const date = new Date(datetime)
      return `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`
    },
    getClassification (data) {
      /**
       * @description - Function for getting classification from data
       * @param {Object} data - Data object
       * @returns {String} - Classification string
       */
      let description = data.split('\n')
      description = description[description.length - 1]
      // Split on "] ["" but only if there is a space after "]"
      description = description.split(/(?<=\])\s(?=\[)/)
      // Return last element and remove [ and ]
      return description[description.length - 1].replace(/[[\]]/g, '')
    },
    getDescription (data) {
      /**
       * @description - Function for getting description from data
       * @param {Object} data - Data object
       * @returns {String} - Description string
       */
      let description = data.split('\n')
      description = description[0]
      return description
    },
    getPriority (num) {
      /**
       * @description - Function for getting priority from number
       * @param {Number} num - Number
       * @returns {String} - Priority string
       */
      const priority = ['Low', 'Normal', 'High']
      return priority[num]
    }
  },
  watch: {
    callType (newValue) {
      // loop through all queues and call update from user details
      for (const value in this.queues) {
        const id = (newValue === 'contact') ? this.queues[value].data.ContactId : this.queues[value].data.AccountId
        getCallUserDetails(newValue, id).then((res) => { // CHANGE to OCDATA once API is fixed
          // console.log('SUCCESS - getCallUserDetails X')
          // console.log(res?.user_details ?? '?')
          this.queues[value].data.consultationTime = res?.user_details?.consultationTime ?? '-'
          this.queues[value].data.consultationTimeToday = res?.user_details?.consultationTimeToday ?? '-'
          this.queues[value].data.numberOfConsultations = res?.user_details?.numberOfConsultations ?? '-'
          this.queues[value].data.numberOfConsultationsToday = res?.user_details?.numberOfConsultationsToday ?? '-'
        }).catch((err) => {
          console.log('ERROR - getCallUserDetails X')
          console.log(err)
        })
      }
    },
    search () {
      this.isLoading.finishedCalls = true
      getAllCalls(this.page, this.per_page, this.search === '' ? null : this.search)
        .then((res) => {
          // console.log('SUCCESS - getAllCalls 2')
          // console.log(res?.all_calls ?? '')
          this.finishedQueues = res?.all_calls ?? {}
          this.isLoading.finishedCalls = false
          this.page = res?.page
        })
        .catch((err) => {
          if (err?.code === 'ERR_CANCELED') return
          console.log('ERROR - getAllCalls 1')
          console.log(err)
          this.isLoading.finishedCalls = true
        })
    },
    page () {
      this.isLoading.finishedCalls = true
      getAllCalls(this.page, this.per_page, this.search === '' ? null : this.search)
        .then((res) => {
          // console.log('SUCCESS - getAllCalls 2')
          // console.log(res?.all_calls ?? '{}')
          this.finishedQueues = res?.all_calls ?? {}
          this.isLoading.finishedCalls = false
        })
        .catch((err) => {
          if (err?.code === 'ERR_CANCELED') return
          console.log('ERROR - getAllCalls 2')
          console.log(err)
          this.isLoading.finishedCalls = true
        })
    },
    per_page () {
      this.isLoading.finishedCalls = true
      getAllCalls(this.page, this.per_page, this.search === '' ? null : this.search)
        .then((res) => {
          // console.log('SUCCESS - getAllCalls')
          // console.log(res?.all_calls ?? '')
          this.finishedQueues = res?.all_calls ?? {}
          this.isLoading.finishedCalls = false
        })
        .catch((err) => {
          if (err?.code === 'ERR_CANCELED') return
          console.log('ERROR - getAllCalls')
          console.log(err)
          this.isLoading.finishedCalls = true
        })
    },
    history_per_page () {
      this.isLoading.historyDialog = true
      getUserPreviousCalls(this.historyOpenID, this.history_page, this.history_per_page, this.historyOpenType).then(res => {
        // console.log('SUCCESS - getUserPreviousCalls')
        // console.log(res?.previous_calls ?? '')
        this.historyQueue = res?.previous_calls ?? {}
        this.isLoading.historyDialog = false
      }).catch((err) => {
        console.log('ERROR - getUserPreviousCalls')
        console.log(err)
        this.isLoading.historyDialog = true
      })
    },
    history_page () {
      this.isLoading.historyDialog = true
      getUserPreviousCalls(this.historyOpenID, this.history_page, this.history_per_page, this.historyOpenType).then(res => {
        // console.log('SUCCESS - getUserPreviousCalls')
        // console.log(res?.previous_calls ?? '')
        this.historyQueue = res?.previous_calls ?? {}
        this.isLoading.historyDialog = false
      }).catch((err) => {
        console.log('ERROR - getUserPreviousCalls')
        console.log(err)
        this.isLoading.historyDialog = true
      })
    },
    dialogQueue_per_page () {
      this.isLoading.dialog = true
      getUserPreviousCalls(this.queues[this.selectedSipId].data.ContactId, this.dialogQueue_page, this.dialogQueue_per_page, 'contact')
        .then((res) => {
          // console.log('SUCCESS - getUserPreviousCalls')
          // console.log(res?.previous_calls ?? '')
          this.dialogQueue = res?.previous_calls ?? []
          this.isLoading.dialog = false
        })
        .catch((err) => {
          console.log('ERROR - getUserPreviousCalls')
          console.log(err)
          this.isLoading.dialog = true
        })
    },
    dialogQueue_page () {
      this.isLoading.dialog = true
      getUserPreviousCalls(this.queues[this.selectedSipId].data.ContactId, this.dialogQueue_page, this.dialogQueue_per_page, 'contact')
        .then((res) => {
          // console.log('SUCCESS - getUserPreviousCalls')
          // console.log(res?.previous_calls ?? '')
          this.dialogQueue = res?.previous_calls ?? []
          this.isLoading.dialog = false
        })
        .catch((err) => {
          console.log('ERROR - getUserPreviousCalls')
          console.log(err)
          this.isLoading.dialog = true
        })
    }
  },
  mounted () {
    // Get new queue data every 10 seconds
    this.isLoading.liveQueue = true
    if (this.queue_interval) clearInterval(this.queue_interval)
    this.queue_interval = setInterval(() => {
      getLiveQueue().then((res) => {
        // console.log('SUCCESS - getLiveQueue')
        // console.log(res?.queue ?? '')
        if (res?.queue) this.storeNewCall(res.queue)
        this.isLoading.liveQueue = false
      }).catch((err) => {
        console.log('ERROR - getLiveQueue')
        console.log(err)
      })
    }, 10500)

    setTimeout(() =>
      getLiveQueue().then((res) => {
        // console.log('SUCCESS - getLiveQueue FIRST')
        // console.log(res?.queue ?? '')
        if (res?.queue) this.storeNewCall(res.queue)
        this.isLoading.liveQueue = false
        getNumberOfPhonecalls().then((res) => {
          // console.log('SUCCESS - getNumberOfPhonecalls')
          // console.log(res?.number_of_phonecalls ?? 0)
          this.consults_today = res?.number_of_phonecalls?.toString() ?? '-' // CHANGE to length once API is fixed
        }).catch((err) => {
          console.log('ERROR - getNumberOfPhonecalls')
          console.log(err ?? 'undefined error')
        })
      }).catch((err) => {
        console.log('ERROR - getLiveQueue FIRST')
        console.log(err ?? 'undefined error')
      }), 250)

    this.isLoading.finishedCalls = true
    setTimeout(() => getAllCalls(this.page, this.per_page, null)
      .then((res) => {
        // console.log('SUCCESS - getAllCalls')
        // console.log(res?.all_calls ?? '{}')
        this.finishedQueues = res?.all_calls ?? {}
        this.isLoading.finishedCalls = false
      })
      .catch((err) => {
        if (err?.code === 'ERR_CANCELED') return
        console.log('ERROR - getAllCalls')
        console.log(err ?? 'undefined error')
        this.isLoading.finishedCalls = true
      }), 2000)
  },
  beforeUnmount () {
    // Make sure to clear intervals so they don't keep running after component is destroyed
    clearInterval(this.queue_interval)
    this.queue_interval = undefined
    clearInterval(this.startNewCall.timerInterval)
    this.startNewCall.timerInterval = undefined
  },
  components: { ClassificationForm, Spinner }
}
</script>

<style>
tr.diffBg {
  background: var(--bg1) !important;
}
.text-subtitle-2 {
  font-size: 0.8rem !important;
  line-height: 1.25;
}
.big-label .v-label {
  font-weight: bold;
  opacity: 1;
  font-size: 0.9rem;
  width: 89%;
}
.radio-list .v-selection-control-group {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
  gap: 1.5rem;
}
.radio-list label {
  font-size: 0.875rem;
}
.title-label {
  font-size: 0.9rem;
  width: 89%;
}
div.radio-list > .v-input__control > .v-selection-control-group {
  flex-direction: row;
  align-items: flex-start;
  gap: 0.5rem;
}
div.radio-list .v-col {
  padding: 0!important;
}
.q-a-form {
  display: flex;
  justify-content: center;
  gap: 1rem;
  flex-flow: row wrap;
  width: 100%;
  padding: 0 12px;
}
.date_picker {
  width: 50%;
}
.v-table {
  background: none;
  color: var(--color);
}
.v-table td, .v-table th, .v-table tr {
  border-bottom: 1px solid var(--color-disabled) !important;
  padding: 0.5rem 16px !important;
}
.v-table .v-table__wrapper > table > thead > tr > th {
  color: var(--color-disabled);
}
.v-table .v-table__wrapper {
  padding-bottom: 1rem !important;
}
.v-card {
  background: white;
}
.v-card .v-row {
  margin: 0;
  /* align-items: center;
  gap: 1.5rem;
  font-size: 0.9rem;
  color: var(--color-disabled); */
}
.v-input__prepend {
  margin-inline-end: 0.25rem!important;
}
.v-select .v-field {
  background: var(--bg3) !important;
  color: var(--color) !important;
}
.radio-button {
  background: var(--bg3);
  color: var(--color);
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: max-content;
  min-width: 170px;
}
.radio-button.v-selection-control--dirty {
  box-shadow: 0px 3px 1px -2px var(--v-shadow-key-umbra-opacity, rgba(0, 0, 0, 0.2)), 0px 2px 2px 0px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.14)), 0px 1px 5px 0px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.12));
  background: var(--accent);
  color: white;
}
.radio-button.v-selection-control--dirty .v-label {
  color: white;
}
.v-card {
  background: white !important;
  backdrop-filter: none !important;
}
.radio-button .v-label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 40px;
  padding-left: 8px;
}
.clickable-queue {
  font-weight: 600 !important;
  cursor: pointer;
  color: var(--accent)!important;
}
.readonly .v-field__overlay,
.readonly .v-field__outline  {
  display: none !important;
}
.readonly {
  opacity: 0.75;
}
.readonly .v-field__input {
  padding-left: 0;
  padding-right: 0;
}
#new-call-timer {
  padding-left: 0.75rem;
  font-weight: 600;
}
</style>
